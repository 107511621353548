
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { fadeIn, fadeInOut } from 'src/app/animations';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { getAverage } from 'src/app/shared/helpers/average.helper';
import { getSum } from 'src/app/shared/helpers/sum.helper';
import { AgentCommissionGroupList, AgentGuidList } from 'src/app/shared/models/agent.models';
import { Policy, PolicyList, PolicyListFilter, PolicyListTypeEnum } from 'src/app/shared/models/policy.models';
import { sidenavConfig } from 'src/app/shared/models/sidenav-config.model';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { PendingTablePolicy } from './models/pendingTablePolicy.model';
import { LineOfBusinessId } from 'src/app/shared/enums/line-business-id.enum';
import { hasAll } from 'src/app/shared/helpers/search.helpers';
import { getPendingCPCSum } from 'src/app/shared/helpers/cpc.helper';
import { downloadFile } from 'src/app/shared/helpers/filedownloader.helper';
import { BusinessRouteParamReportTypeEnum } from '../../enums/policy-v2.enums';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  animations: [fadeIn, fadeInOut],
  selector: 'app-pending-policy-list',
  templateUrl: './pending-policy-list.component.html',
  standalone: false
})
export class PendingPolicyListComponent implements OnInit, PolicyList {
  @ViewChild('sidenav') sidenav?: MatSidenav;
  policyListType: PolicyListTypeEnum = PolicyListTypeEnum.Pending;
  allPolicies: Policy[] = [];
  filteredPolicies: Policy[] = [];
  currentFilters: PolicyListFilter = new PolicyListFilter();
  isError = false;
  isLoading = true;
  reportType?: BusinessRouteParamReportTypeEnum;
  searchText: string | null = null;
  sidenavConfig: sidenavConfig = {
    IsOpened: true,
    Mode: 'side',
  };
  getSum = getSum;
  getAverage = getAverage;
  getCPCSum = getPendingCPCSum;
  isExporting = false;
  needToQueryAgentPolicies = true;
  pendingPolicies: PendingTablePolicy[] = [];
  averagePremium = 0;
  totalPremium = 0;
  policyCount = 0;
  routeTitle?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private amplitudeEventService: AmplitudeEventService,
    private reportsApiService: ReportsApiService,
    private snackbar: SnackbarService,
  ) {
    this.routeTitle = this.activatedRoute.snapshot.data['title'] as string | undefined;
  }

  ngOnInit(): void {
    this.reportType = this.activatedRoute.snapshot.data['reportType'] as BusinessRouteParamReportTypeEnum;
  }

  agentFilter(policy: Policy): boolean {
    const agentIds = this.currentFilters.SelectedAgentIds;
    let match = false;
    if (agentIds && agentIds.length > 0) {
      match = agentIds.some(function (a) {
        return (a.replace(/[{}]/g, '').toLowerCase() === policy.AgentGuid.replace(/[{}]/g, '').toLowerCase())
          || (policy.AssociatedPortalAgentGUIDS != null ? policy.AssociatedPortalAgentGUIDS.split(',').some(function (aa: string) {
            return a.replace(/[{}]/g, '').toLowerCase() === aa.replace(/[{}]/g, '').toLowerCase();
          }) : false);
      });
    }
    return match;
  }

  export(): void {
    if (this.filteredPolicies.length === 0) {
      this.snackbar.openSnackbar('No policies. Please select different filters.', ToastClassEnum.default);
    } else {
      this.snackbar.openSnackbar('Exporting policies, please wait.', ToastClassEnum.default);
      const model = {
        IsInforceReport: false,
        AveragePremium: this.averagePremium,
        TotalPremium: this.totalPremium,
        Policies: this.filteredPolicies
      };
      this.isExporting = true;
      this.reportsApiService.exportPolicies(model).subscribe({
        next: blob => {
          downloadFile(blob, 'PolicyData.xlsx');
          this.snackbar.closeSnackbar();
        },
        error: () => {
          this.snackbar.openSnackbar('Sorry, an error has occurred. Please try again later.', ToastClassEnum.warning);
        }
      }).add(() => {
        this.isExporting = false;
      });
    }
  }

  getAgentGuidList(): AgentGuidList {
    // All agent and lob id's are being used since we are filtering on the front end
    return {
      AgentIds: this.currentFilters.AllAgentIds.join(','),
      LobIds: this.currentFilters.AllLobIds,
      StartDate: this.currentFilters.StartDate,
      EndDate: this.currentFilters.EndDate,
      IncludeUser: false,
      CanSeeAgentCommissions: false
    };
  }

  getCommissionGroupList(): AgentCommissionGroupList {
    return {
      ParentAgentGuid: this.currentFilters.ParentAgentGuid,
      CommissionGroupIds: this.currentFilters.SelectedSubEntities.join(','),
      LobIds: this.currentFilters.AllLobIds,
      StartDate: this.currentFilters.StartDate,
      EndDate: this.currentFilters.EndDate,
      IncludeUser: false,
      CanSeeAgentCommissions: false
    };
  }

  getFilteredPolicies(): Policy[] {
    return this.allPolicies.filter(policy => {
      return this.lobFilter(policy) && (this.currentFilters.SelectedSubEntities.length > 0 || this.agentFilter(policy)) && this.searchTextFilter(policy);
    });
  }

  updateReportType(): void {
    this.reportType = this.activatedRoute.snapshot.data['reportType'] as BusinessRouteParamReportTypeEnum;
    if (this.currentFilters && this.currentFilters.AllAgents && this.currentFilters.AllAgents.length > 0) {
      this.getPolicies();
    }
  }

  getSubEntityPolicies(): void {
    const commissionGroupList: AgentCommissionGroupList = this.getCommissionGroupList();
    this.isLoading = true;

    let httpCall: Observable<Policy[]>;
    if (this.reportType === BusinessRouteParamReportTypeEnum.fixed) httpCall = this.reportsApiService.getCommissionGroupPendingPolicies(commissionGroupList);
    else httpCall = this.reportsApiService.getCommissionGroupPendingVariablePolicies(commissionGroupList);

    httpCall.subscribe({
      next: policies => {
        this.allPolicies = policies;
        this.setTable();
        this.amplitudeEventService.logBusinessReporting(this.reportType || 'undefined', this.policyListType, this.allPolicies.length);
        this.isLoading = false;
        this.isError = false;
        this.needToQueryAgentPolicies = true;
      },
      error: () => {
        this.isError = true;
        this.isLoading = false;
        this.snackbar.openSnackbar('Sorry, an error has occurred. Please try again later.', ToastClassEnum.warning);
        this.needToQueryAgentPolicies = true;
      }
    });
  }

  getPolicies(): void {
    const agentGuidList = this.getAgentGuidList();
    this.isLoading = true;
    let httpCall: Observable<Policy[]>;
    if (this.reportType === BusinessRouteParamReportTypeEnum.fixed) httpCall = this.reportsApiService.getPendingPolicies(agentGuidList);
    else httpCall = this.reportsApiService.getPendingVariablePolicies(agentGuidList);

    httpCall.subscribe({
      next: policies => {
        this.allPolicies = policies;
        this.setTable();
        this.amplitudeEventService.logBusinessReporting(this.reportType || 'Undefined', this.policyListType, this.allPolicies.length);
        this.isLoading = false;
        this.isError = false;
        this.needToQueryAgentPolicies = false;
      },
      error: () => {
        this.isError = true;
        this.isLoading = false;
        this.snackbar.openSnackbar('Sorry, an error has occurred. Please try again later.', ToastClassEnum.warning);
        this.needToQueryAgentPolicies = false;
      }
    });
  }

  lobFilter(policy: Policy): boolean {
    let match = false;
    if (this.currentFilters.SelectedLobIds && this.currentFilters.SelectedLobIds.length > 0) {
      match = this.currentFilters.SelectedLobIds.some((lobId) => lobId === policy.LineOfBusinessId);
    }
    return match;
  }

  onFilterChange(filters: PolicyListFilter): void {
    this.currentFilters = filters;

    if (this.currentFilters.SelectedSubEntities.length > 0) {
      this.getSubEntityPolicies();
    } else if (this.needToQueryAgentPolicies || (!this.allPolicies || this.currentFilters.IsClearingPolicies)) {
      //if policies haven't been loaded make api call OR if changing from subEntities to subAgents
      this.getPolicies();
    } else {
      // else just filter existing policies
      this.setTable();
    }
  }


  searchTextFilter(policy: Policy): boolean {
    if (!this.searchText) return true;
    return hasAll(policy, this.searchText);
  }

  setSearchText(searchText: string | null): void {
    this.searchText = searchText;
    this.setTable();
  }

  setTable(): void {
    this.isLoading = true;
    this.filteredPolicies = this.getFilteredPolicies();
    this.pendingPolicies = this.filteredPolicies.map(p => {
      let premium: number | null = null;
      if (p.LineOfBusinessId === LineOfBusinessId.Annuity) {
        premium = p.SinglePremium;
      } else if (p.TotalIssuedPremium) {
        premium = p.TotalIssuedPremium;
      } else if (p.ModalPremium) {
        premium = p.ModalPremium;
      }

      return {
        Agent: p.FirstName + ' ' + p.LastName,
        ActionNeeded: p.ActionNeeded,
        Carrier: p.Carrier,
        Clients: p.Clients,
        FaceAmount: p.FaceAmount,
        Target: p.Target,
        HasChecklistItem: p?.HasChecklistItem ?? false,
        LineOfBusinessId: p.LineOfBusinessId,
        PolicyGuid: p.PolicyGuid,
        PolicyNumber: p.PolicyNumber,
        PolicyStatus: p.PolicyStatus,
        PolicyStatusDate: p.PolicyStatusDate,
        Premium: premium,
        Product: p.Product,
        IsVariable: p.IsVariable,
        EstimatedCPCs: p.IssuedCPCs || p.CPCs
      };
    });

    this.averagePremium = this.getAverage(this.filteredPolicies.filter(t => !t.IsPartial), 'SinglePremium');
    this.totalPremium = this.getSum(this.filteredPolicies.filter(t => !t.IsPartial), 'SinglePremium');
    this.policyCount = this.filteredPolicies.filter(t => !t.IsPartial).length;


    this.isLoading = false;
  }

  toggleSideNavMenu(): void {
    this.sidenav?.toggle();
  }
}