import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PolicyList, PolicyListTypeEnum } from 'src/app/shared/models/policy.models';
import { fadeIn, fadeInOut } from 'src/app/animations';
import { BusinessRouteParamReportTypeEnum } from './enums/policy-v2.enums';

@Component({
  animations: [fadeIn, fadeInOut],
  selector: 'app-business',
  templateUrl: './business.component.html',
  standalone: false
})
export class BusinessComponent implements OnInit {
  childComponentReference: PolicyList | undefined;
  isExporting!: boolean;
  loading!: boolean;
  policyListType?: PolicyListTypeEnum;
  policyTypeEnum = PolicyListTypeEnum;
  searchText: string | null = null;
  selectedReportType!: string;
  isAcrBasedReport = false;
  isDetailsPage = false;
  @ViewChild('business-container-content')
  private businessContentDiv!: ElementRef<HTMLElement>;
  arcBasedReportTypes: string[] = [BusinessRouteParamReportTypeEnum.fixed, BusinessRouteParamReportTypeEnum.variable, BusinessRouteParamReportTypeEnum.wealth];

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackbar: MatSnackBar,
    private router: Router,) { }

  ngOnInit(): void {
    this.selectedReportType = this.activatedRoute.snapshot.firstChild?.data['reportType'] as string;
    this.updatePageProperties();
  }

  changeReportType(reportType: string): void {
    this.selectedReportType = reportType;
    this.updatePageProperties();
    if (this.childComponentReference && this.childComponentReference.updateReportType) {
      this.childComponentReference?.updateReportType();
    }
  }

  clearSearchText(): void {
    this.searchText = null;
    this.childComponentReference?.setSearchText(null);
  }

  onActivate(childComponentReference: PolicyList): void {
    if (this.businessContentDiv) {
      (this.businessContentDiv.nativeElement as HTMLElement).scrollTop = 0;
    }
    this.childComponentReference = childComponentReference;
    this.policyListType = this.childComponentReference.policyListType;
    this.changeReportType(this.activatedRoute.snapshot.firstChild?.data['reportType']);
  }

  openSnackbar(message: string, toastClass: string): void {
    this.snackbar.open(message, 'Close', {
      panelClass: [toastClass]
    });
  }

  updatePageProperties(): void {
    this.isAcrBasedReport = this.arcBasedReportTypes.includes(this.selectedReportType);
    this.isDetailsPage = this.selectedReportType === BusinessRouteParamReportTypeEnum.policyDetails;
  }
}
