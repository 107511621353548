// 06-2023 event: any


/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fadeIn } from 'src/app/animations';
import { UserApiService } from 'src/app/core/services/http/user-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { ChangeLogoCyIdConst } from 'cypress/e2e/features/change-logo-submission/constants/change-logo-submissions.const';
import { figLogoUrl } from 'src/app/shared/constants/misc.constants';
import { ConfirmationData } from 'src/app/shared/models/modal-data.models';
import { User } from 'src/app/shared/models/user.models';
import { ConfirmationComponent } from '../confirmation/confirmation.component';

@Component({
  selector: 'app-user-logo-upload',
  templateUrl: './user-logo-upload.component.html',
  animations: [fadeIn],
  standalone: false
})
export class UserLogoUploadComponent implements OnInit {
  cyId = ChangeLogoCyIdConst;
  dragover = false;
  uploadingFile!: File | null;
  user!: User;
  logo!: string | null;
  figLogo: string = figLogoUrl;
  refresh = false;
  previewUrl!: string | null;
  snackbarData: { class: 'is-success' | 'is-error', message: string } = {
    message: '',
    class: 'is-error',
  };
  showInlineSnackbar = false;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<UserLogoUploadComponent>,
    private userApiService: UserApiService,
    private sessionStore: SessionStoreService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: {logo: string | null;} ,
  ) { }

  ngOnInit(): void {
    this.user = this.sessionStore.OnBehalfOfUser ? this.sessionStore.OnBehalfOfUser : this.sessionStore.User;
    this.logo = this.data.logo;
  }

  uploadLogo(): void {
    const formData = new FormData();
    if (this.uploadingFile && this.uploadingFile.size >= 1) formData.append('user_logo', this.uploadingFile as File, this.uploadingFile?.name);
    this.userApiService.postUserLogo(this.user.Id, formData).subscribe({
      next: () => {
        this.refresh = true;
        this.user.HasUserLogo = true;
        this.dialogRef.close({ refresh: this.refresh, hasPhoto: this.user.HasUserLogo });
      }
    });
  }

  dragOverHandler(event: DragEvent): void {
    this.dragover = true;
    event.preventDefault();
    event.stopPropagation();
  }

  uploadFiles(event: Event): void {
    this.dragover = false;
    const eventTarget = event.target as HTMLInputElement;
    let file: File | null = null;
    if (eventTarget) {
      file = eventTarget.files?.length ? eventTarget.files[0] : null;
    }
    if (file) {
      if (this.isValidFile(file).isSuccess) {
        this.uploadingFile = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
          this.previewUrl = reader.result as string;
        };
        eventTarget.value = '';
      } else {
        this.snackbarData = {
          message: '',
          class: 'is-error',
        };

        if (this.isValidFile(file).errorType === 'size') this.snackbarData.message = 'File size must be less than 2MB.';
        if (this.isValidFile(file).errorType === 'ext') this.snackbarData.message += ' File type must be a valid image type.';
        this.snackbarData.message += ' Please select a different logo image.';
        this.showInlineSnackbar = true;
      }
    }
  }

  isValidFile(file: File): { isSuccess: boolean, errorType: 'size' | 'ext' | null } {
    const fileName: string = file.name;
    const fileSize: number = file.size;
    const idxDot = fileName.lastIndexOf('.') + 1;
    const extFile = fileName.substring(idxDot, fileName.length).toLowerCase();
    const extCheck: boolean = (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png');
    const sizeCheck: boolean = fileSize <= 2000000;

    return {
      isSuccess: extCheck && sizeCheck,
      errorType: !extCheck ? 'ext' : !sizeCheck ? 'size' : null,
    };
  }

  removeFile(): void {
    this.uploadingFile = null;
    this.previewUrl = null;
  }

  closeModal(event: any, refresh: boolean): void {
    this.dialogRef.close({ refresh: refresh, hasPhoto: this.user.HasUserLogo });
  }

  deleteLogo(): void {
    this.userApiService.deleteUserLogo(this.user.Id).subscribe({
      next: res => {
        console.log(res);
        this.user.HasUserLogo = false;
        this.logo = null;
        this.refresh = true;
      }
    });
  }

  confirmDeleteLogo(): void {
    const confirmDialog = this.dialog.open<ConfirmationComponent, ConfirmationData>(ConfirmationComponent, {
      data: {
        title: 'Confirm Removing Current Logo',
        message: 'Are you sure you want to remove the current logo? The default logo will be the Financial Independence Group logo.',
        confirmationButtonText: 'Remove Logo',
        isWarning: true,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.deleteLogo();
      } else {
        return;
      }
    });

  }
}
