import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SupportComponent } from 'src/app/portal/modals/support/support.component';

@Component({
  selector: 'app-wealthbox-auth-redirect',
  templateUrl: './wealthbox-auth-redirect.component.html',
  standalone: false
})
export class WealthboxAuthRedirectComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
  ) {

  }
  ngOnInit(): void {
    const uri = decodeURIComponent(this.activatedRoute.snapshot.queryParamMap.get('redirect_uri'));
    console.log(uri);
    window.location.href = uri;
  }
  openSupportModal(): void {
    this.dialog.open(SupportComponent, {
      height: '74rem',
    });
  }
}
