
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SessionStoreService } from './core/services/stores/session-store.service';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { ConfigService } from './core/services/config/config.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { SharedModule } from './shared/shared.module';
import { PortalModule } from './portal/portal.module';
import { tooltipDefaultOptions } from './shared/config/mat-tooltip.config';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DevelopmentGuardService } from './core/services/guards/development-guard/development-guard.service';
import { AuthService } from './core/services/auth/auth.service';
import { matSnackBarDefaultConfig } from './shared/config/mat-snack-bar.config';
import { matDialogDefaultConfig } from './shared/config/mat-dialog.config';
import { AuthModule } from './auth/auth.module';
import { OldPortalRouterService } from './core/services/old-portal-router/old-portal-router.service';
import { RiaAgent } from './shared/models/ria.models';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WealthboxAuthCompleteComponent } from './externals/wealthbox-auth-complete/wealthbox-auth-complete.component';
import { WealthboxAuthRedirectComponent } from './externals/wealthbox-auth-redirect/wealthbox-auth-redirect.component';
import { WealthboxAuthErrorComponent } from './externals/wealthbox-auth-error/wealthbox-auth-error.component';
import { AmplitudeEventService } from './core/services/amplitude/amplitude-event.service';
import { SalesforceAuthRedirectComponent } from './externals/salesforce-auth/salesforce-auth-redirect/salesforce-auth-redirect.component';
import { SalesforceAuthCompleteComponent } from './externals/salesforce-auth/salesforce-auth-complete/salesforce-auth-complete.component';
import { SalesforceAuthErrorComponent } from './externals/salesforce-auth/salesforce-auth-error/salesforce-auth-error.component';
import { AmplitudeEventV2Service } from './core/services/amplitude/amplitude-event-v2.service';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';
import { CoreCommissionsSsoErrorComponent } from './externals/core-commissions/core-commissions-sso-error/core-commissions-sso-error.component';

const borderModules = [
  BrowserAnimationsModule,
  BrowserModule,
];
@NgModule({
  declarations: [
    AppComponent,
    WealthboxAuthCompleteComponent,
    WealthboxAuthRedirectComponent,
    WealthboxAuthErrorComponent,
    SalesforceAuthRedirectComponent,
    SalesforceAuthCompleteComponent,
    SalesforceAuthErrorComponent,
    CoreCommissionsSsoErrorComponent,
  ],
  imports: [
    ...borderModules,
    SharedModule,
    PortalModule,
    AppRoutingModule,
    AuthModule,
  ],
  providers: [
    DevelopmentGuardService,
    {
      provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
      useValue: {
        hideSingleSelectionIndicator: true,
        hideMultipleSelectionIndicator: true,
      },
    },
    //Mat tooltip global config
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: tooltipDefaultOptions,
    },
    //Mat dialog global config
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: matDialogDefaultConfig,
    },
    //Angular Snackbar global config
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: matSnackBarDefaultConfig,
    },
    {
      provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
      useValue: {
        hideSingleSelectionIndicator: true,
        hideMultipleSelectionIndicator: true,
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ConfigService) => (): Promise<void> => config.setPermissions(),
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (oldPortalRouter: OldPortalRouterService) => (): void => oldPortalRouter.handleOldPortalLinks(),
      deps: [OldPortalRouterService],
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (
        ss: SessionStoreService,
        as: AuthService,
        amplitude: AmplitudeEventService,
        amplitudeV2: AmplitudeEventV2Service
      ) => {
        return (): Promise<RiaAgent | undefined | void> | void => {
          // This check is needed for people going to SSO pages etc.. that don't require them to be actively logged in
          if (as.isAuthenticated()) {
            return ss.setUserV2()
              .then(user => {
                if (user) {
                  // We only start polling when user is fully logged in to prevent cases when user is logging in & the timer interferes before the cookies are fully set
                  as.pollCookieTimerExpiration();
                  return ss.setUserGroup(user);
                } else {
                  // This is for edge case where session cookie is active, but auth cookie is not. This will cause the app to fail if there is no user. So redirect them to login
                  as.logIn(undefined, undefined, true);
                  return null;
                }
              })
              .then(() => ss.setOnBehalfOfUserV2())
              .then(() => ss.setUserLocalStorageData())
              .then(() => ss.setUserLogo())
              .then(() => ss.setUserAcmAgentV2())
              .then(() => ss.setUserFigAuthId())
              .then(() => {
                amplitudeV2.initAmplitude();
              });
          } else {
            return console.error('Not authenticated');
          }
        };
      },
      deps: [SessionStoreService, AuthService, AmplitudeEventService, AmplitudeEventV2Service],
      multi: true
    },

    Title
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
