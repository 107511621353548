
// 06-2022: cleanup Form group typing
// 06-2023 event: any
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { MarketingDollarApiService } from 'src/app/core/services/http/marketingdollar-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { marketingDollarStates, paymentTypes, purchaseCategories } from 'src/app/shared/constants/marketingDollars.constants';
import { AchVendorModel, BasicModel, EnvironmentEnum, State } from 'src/app/shared/models/generic.models';
import { MarketingDollarEmailData, MarketingDollarPointAdjustment, AgentMarketingDollarsResponse } from 'src/app/shared/models/marketingdollar.models';
import { User } from 'src/app/shared/models/user.models';
import { FeatureToggleService } from '../../../core/services/featuretoggle.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { ConfirmationData } from 'src/app/shared/models/modal-data.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';

@Component({
  selector: 'app-redeem-marketing-dollars',
  templateUrl: './redeem-marketing-dollars.component.html',
  standalone: false
})

export class RedeemMarketingDollarsComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<RedeemMarketingDollarsComponent>,
    private fb: UntypedFormBuilder,
    private featureToggleService: FeatureToggleService,
    private marketingDollarApiService: MarketingDollarApiService,
    private sessionStore: SessionStoreService,
    private _snackBar: MatSnackBar,
    private snackbarService: SnackbarService,) {
    dialogRef.disableClose = true;
  }
  agentMarketingDollar: AgentMarketingDollarsResponse = {
    Entry: {
      AgentId: '',
      MarketingDollarsTotal: 0,
    },
    Details: []
  };
  activeStep = 0;
  disableNext = false;
  filesToUpload: File[] = [];
  hasSubmitted = false;
  isUploading = false;
  isSubmitting = false;
  marketingDollarRequestPaymentType!: BasicModel;
  onBehalfOfUser!: User | null;
  paymentTypes: BasicModel[] = paymentTypes;
  // TODO: cleanup Form group typing
  pointAdjustmentForm: any = this.fb.group({});
  proceedNegative = false;
  purchaseCategories: BasicModel[] = purchaseCategories;
  request: MarketingDollarPointAdjustment = {
    FileIDs: [],
    MarketingDollarCategoryIDs: [],
    MarketingDollarRequestPaymentTypeID: 0,
    MarketingDollarRequestPaymentTypeIDs: [],
    PhysicalAddress: { PhysicalAddressID: null, Line1: '', Line2: '', City: '', StateCode: '', StateID: null, PostalCode: '', PhysicalAddressTypeID: 0, PreferenceOrder: 0, MarketingDollarRequestID: null, ExternalUserGuid: null },
    RequestedOn: new Date().toLocaleDateString(),
    CompletedOn: null,
    SalesTax: 0,
    InvoiceAmount: 0,
    MarketingDollarAmount: 0,
    MarketingDollarCategoryID: 0,
    PayeeName: null,
    PayeeTin: null,
    OtherPaymentNotes: '',
    Notes: '',
    ExternalUserGuid: null,
    ExternalAgentGuid: null,
    SendEmailTo: null,
    RequestedByEmailAddress: null
  };
  remainingBalance = 0;
  states: State[] = marketingDollarStates;
  state: State = { StateID: null, State: '', StateName: null, IsVisible: null };
  uploadForm!: Form;
  user!: User;
  vendors: AchVendorModel[] = [];
  dragover = false;

  ngOnInit(): void {
    this.user = this.sessionStore.User;
    this.onBehalfOfUser = this.sessionStore.OnBehalfOfUser;
    this.createForm();

    this.marketingDollarApiService.getUserMarketingDollars().subscribe(response => {
      if (response) {
        this.agentMarketingDollar = response;
        this.updateRemainingBalance();
      } else {
        return;
      }
    });

    this.marketingDollarApiService.getVendors().subscribe(response => {
      this.vendors = response;
    });

    const environment = this.featureToggleService.getEnvironment();

    if (environment === EnvironmentEnum.prod) {
      this.request.SendEmailTo = this.onBehalfOfUser ? this.onBehalfOfUser.Email : this.user.Email;
    } else {
      this.request.SendEmailTo = 'devteam@figmarketing.com';
    }

    // we're checking the payment type to set conditional validation on the subsequent inputs
    this.pointAdjustmentForm.get('paymentType')?.valueChanges.subscribe((value: number) => {
      const check = this.pointAdjustmentForm.get('paymentTypeCheck') as UntypedFormGroup;
      const ach = this.pointAdjustmentForm.get('paymentTypeAch') as UntypedFormGroup;
      const other = this.pointAdjustmentForm.get('paymentTypeOther') as UntypedFormGroup;
      if (value === 1) {
        this.setRequireValidator(check);
        this.removeValidator(ach);
        this.removeValidator(other);
      } else if (value === 5) {
        this.setRequireValidator(ach);
        this.removeValidator(other);
        this.removeValidator(check);
      } else if (value === 2005) {
        this.setRequireValidator(other);
        this.removeValidator(ach);
        this.removeValidator(check);
      }
    });
  }

  //in case we need to disable form validation for testing
  removeAllValidators(): void {
    const form = this.pointAdjustmentForm;
    const check = this.pointAdjustmentForm.get('paymentTypeCheck') as UntypedFormGroup;
    const ach = this.pointAdjustmentForm.get('paymentTypeAch') as UntypedFormGroup;
    this.removeValidator(form);
    this.removeValidator(check);
    this.removeValidator(ach);
    this.removeValidator(check);
  }

  //loop thru a form group and set required fields
  private setRequireValidator(form: UntypedFormGroup): void {
    for (const field in form.controls) { // 'field' is a string
      const con = form.get(field); // 'control' is a FormControl
      con?.setValidators([Validators.required]);
      con?.updateValueAndValidity();
    }

  }

  //loop thru a form group and remove required fields
  private removeValidator(form: UntypedFormGroup): void {
    for (const field in form.controls) { // 'field' is a string
      const con = form.get(field); // 'control' is a FormControl
      con?.clearValidators();
      con?.updateValueAndValidity();
    }
  }

  //This form awaits the user obj to be returned first so we call it after the subscribe block below
  private createForm(): void {
    this.pointAdjustmentForm = this.fb.group({
      purchaseCategory: ['', [Validators.required]],
      invoiceAmount: ['', [Validators.required, Validators.min(0)]],
      paymentType: ['', Validators.required],
      paymentTypeCheck: this.fb.group({
        vendorName: [''],
        vendorStreetAddress: [''],
        vendorCity: [''],
        vendorState: [''],
        vendorZip: [''],
      }),
      paymentTypeAch: this.fb.group({
        vendorName: [''],
      }),
      paymentTypeOther: this.fb.group({
        paymentTypeName: ['']
      }),
      contactEmail: [this.user.Email, [Validators.required]],
      notes: [''],
      fileUploads: [null, [Validators.required]],
    });
  }

  nextStep(): void {
    if (this.activeStep < 5) this.activeStep += 1;
  }

  prevStep(): void {
    if (this.activeStep === 0) this.dialogRef.close(false);
    else this.activeStep -= 1;
  }

  updateRemainingBalance(): void {
    this.remainingBalance = this.pointAdjustmentForm.get('invoiceAmount')?.value ? this.agentMarketingDollar.Entry.MarketingDollarsTotal - Number(this.pointAdjustmentForm.get('invoiceAmount')?.value) : this.agentMarketingDollar.Entry.MarketingDollarsTotal;
  }

  private createRequestPayload(): void {
    this.isSubmitting = true;
    const payload: MarketingDollarPointAdjustment = {
      ...this.request,
      InvoiceAmount: this.pointAdjustmentForm.get('invoiceAmount')?.value,
      MarketingDollarAmount: this.pointAdjustmentForm.get('invoiceAmount')?.value,
      MarketingDollarCategoryID: this.pointAdjustmentForm.get('purchaseCategory')?.value,
      MarketingDollarCategoryIDs: [this.pointAdjustmentForm.get('purchaseCategory')?.value],
      MarketingDollarRequestPaymentTypeID: this.pointAdjustmentForm.get('paymentType')?.value,
      MarketingDollarRequestPaymentTypeIDs: [this.pointAdjustmentForm.get('paymentType')?.value],
      Notes: 'Portal Request Notes: ' + (this.pointAdjustmentForm.get('notes')?.value.length > 0 ? this.pointAdjustmentForm.get('notes')?.value : 'No notes.'),
      SendEmailTo: this.pointAdjustmentForm.get('contactEmail')?.value
    };

    if (this.pointAdjustmentForm.get('paymentType')?.value === 1) {
      payload.PayeeName = this.pointAdjustmentForm.get('paymentTypeCheck.vendorName')?.value;
      payload.PhysicalAddress.Line1 = this.pointAdjustmentForm.get('paymentTypeCheck.vendorStreetAddress')?.value;
      payload.PhysicalAddress.City = this.pointAdjustmentForm.get('paymentTypeCheck.vendorCity')?.value;
      payload.PhysicalAddress.StateID = this.pointAdjustmentForm.get('paymentTypeCheck.vendorState')?.value.StateID;
      payload.PhysicalAddress.StateCode = this.pointAdjustmentForm.get('paymentTypeCheck.vendorState')?.value.State;
      payload.PhysicalAddress.PostalCode = this.pointAdjustmentForm.get('paymentTypeCheck.vendorZip')?.value;
    }

    if (this.pointAdjustmentForm.get('paymentType')?.value === 5)
      payload.PayeeName = this.pointAdjustmentForm.get('paymentTypeAch.vendorName')?.value;

    // for "Other" payment type, concat payment notes to form.Notes
    if (this.pointAdjustmentForm.get('paymentType')?.value === 2005) {
      payload.OtherPaymentNotes = this.pointAdjustmentForm.get('paymentTypeOther.paymentTypeName')?.value;
      payload.Notes = payload.Notes + '\n' + 'Payment Type Note: ' + this.pointAdjustmentForm.get('paymentTypeOther.paymentTypeName')?.value;
    }

    this.request = payload;
  }


  onSubmit(): void {
    if (this.pointAdjustmentForm.invalid) {
      this.pointAdjustmentForm.markAllAsTouched();
    } else {
      this.createRequestPayload();

      this.marketingDollarApiService.submitMarketingDollarPointAdjustmentForm(this.request)
        .subscribe(
          res => {
            const requestId = res;
            if (requestId != null) {
              this.hasSubmitted = true;
              return this.sendFilesToArcRequest(requestId)
                .subscribe({
                  next: () => {
                    this.activeStep = 5;
                    this.isSubmitting = false;
                    this.isUploading = false;
                    return this.sendEmail(requestId, this.request.SendEmailTo);
                  }
                });
            }
            return null;
          },
          () => {
            this.activeStep = 1;
            const message = 'There was a problem creating your request. Please try again shortly.';
            const toastClass = 'is-error';
            this._snackBar.open(message, 'Close', {
              panelClass: [toastClass]
            });
          });
    }
  }


  createAdjustmentRequest(): void {
    this.isSubmitting = true;
    this.request.MarketingDollarAmount = this.request.InvoiceAmount;
    this.request.MarketingDollarCategoryIDs.push(this.request.MarketingDollarCategoryID);
    this.request.MarketingDollarRequestPaymentTypeID = this.marketingDollarRequestPaymentType.Id;
    this.request.MarketingDollarRequestPaymentTypeIDs.push(this.request.MarketingDollarRequestPaymentTypeID);
    this.request.Notes = 'Portal Request Notes ' + (this.request.Notes ? this.request.Notes : 'No notes.');

    // for "Other" payment type, concat payment notes to form.Notes
    if (this.marketingDollarRequestPaymentType.Id === 2005) {
      this.request.Notes = this.request.Notes + '\n' + 'Payment Type Note: ' + this.request.OtherPaymentNotes;
    }

    this.request.PhysicalAddress.StateID = this.state.StateID ? this.state.StateID : null;
    this.request.PhysicalAddress.StateCode = this.state.State;
    this.request.RequestedByEmailAddress = null;

    this.marketingDollarApiService.submitMarketingDollarPointAdjustmentForm(this.request)
      .subscribe(
        res => {
          const requestId = res;
          if (requestId != null) {
            this.hasSubmitted = true;
            return this.sendFilesToArcRequest(requestId)
              .subscribe(() => {
                this.activeStep = 5;
                return this.sendEmail(requestId, this.request.SendEmailTo);
              });
          }
          return null;
        },
        () => {
          this.activeStep = 1;
          const message = 'There was a problem creating your request. Please try again shortly.';
          const toastClass = 'is-error';
          this._snackBar.open(message, 'Close', {
            panelClass: [toastClass]
          });
        },
        () => {
          this.isSubmitting = false;
          this.isUploading = false;
        });
  }

  openNegativeConfirmation(): void {
    this.dialog.open<ConfirmationComponent, ConfirmationData>(ConfirmationComponent, {
      data: {
        message: 'This marketing expense request exceeds the amount of points available. Do you wish to proceed?',
        isWarning: true,
        confirmationButtonText: 'Proceed'
      }
    }).afterClosed().subscribe({
      next: res => {
        if (res) {
          this.proceedNegative = true;
          this.nextStep();
        } else return;
      }
    });
  }

  sendFilesToArcRequest(requestId: number): Observable<number> {
    const formData = new FormData();
    this.isUploading = true;

    this.filesToUpload.forEach(file => {
      formData.append('file', file);
    });


    if (requestId) {
      return this.marketingDollarApiService.uploadToRequest(requestId, formData);
    } else {
      return throwError('No request Id');
    }
  }

  dragOverHandler(event: DragEvent): void {
    this.dragover = true;
    event.preventDefault();
    event.stopPropagation();
  }

  onFileInput(event: any): void {
    this.dragover = false;
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      if (files[i].size >= 1) this.filesToUpload.push(files[i]);
      else this.snackbarService.openSnackbar('File cannot be empty.',ToastClassEnum.warning);
    }
  }

  removeFile(file: File): void {
    const index = this.filesToUpload.indexOf(file);
    this.filesToUpload.splice(index, 1);
  }

  sendEmail(requestId: number, sendToEmail: string): void {
    const emailData: MarketingDollarEmailData = {
      FirstName: this.onBehalfOfUser ? this.onBehalfOfUser.FirstName : this.user.FirstName,
      LastName: this.onBehalfOfUser ? this.onBehalfOfUser.LastName : this.user.LastName,
      sendToEmail: sendToEmail
    };
    this.marketingDollarApiService.sendMarketingDollarPointAdjustmentEmails(requestId, emailData).subscribe();
  }
}
