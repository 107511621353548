export const ContractingPermissions = ['SureLc:Access'];
export const ExplorePermissions = ['Explore:View'];
export const MarketingPermissions = ['MarketingTracker:Access', 'CreativeServices:Access', 'ElevateForLife:Access', 'Elevate:Access', 'ElevateEnhanced:Access'];
export const MarketingTrackerPermissions = ['MarketingTracker:Access'];
export const MarketplaceOnePermissions = ['CreativeServices:Access', 'ElevateForLife:Access', 'Elevate:Access', 'ElevateEnhanced:Access'];
export const BusinessPermissions = {
  allBusiness: ['Reports:NewBusiness', 'IpipelineEApps:Access', 'Firelight:Access'],
  newBusiness: ['Reports:NewBusiness'],
  eApps: ['IpipelineEApps:Access', 'Firelight:Access'],
  lifecycleV2: ['LifeCycleII:View', 'Reports:NewBusiness'],
};
export const StructuredMarketplacePermissions = ['MarketplaceStructuredInvestments:View'];
export const PaperApplicationPermissions = ['SubmitPaperApp:View'];
export const LumaPermissions = ['Luma:Access'];
export const AnnuityRateWatchPermissions = ['AnnuityRateWatch:Access'];
export const TermLifeQuotePermissions = ['IpipelineFormsQuotesLife:Access', 'LifeQuoteEngine:View'];
export const EnsightPermissions = ['Ensight:Access'];
export const AnnexusPermissions = ['AnnexusQuotes:Access'];
export const LifeProductSearchPermissions = ['IpipelineFormsQuotesLife:Access', 'LifeProductSearch:View'];
export const PolicyGeniusPermissions = ['Policygenius:View'];
export const RetireUpProPermissions = ['RetireUp:View'];
export const SPIADIAPermissions = ['Cannex:Access'];
export const LctiQuotePermissions = ['LtciPartnersWebsite:View'];
export const CareMatterQuotePermissions = ['LtcLiabilityReleaseForm:View'];
export const LifeInsuranceEducationPermissions = ['EducationLifeInsurance:View'];
export const MedicarePlanningPermissions = ['MedicarePlanning:View'];
export const DisabilityEducationPermissions = ['EducationDisability:View'];
export const CarePlanningEducationPermissions = ['EducationCarePlanning:View'];
export const FigEventsPermissions = ['IndustryNews:View', 'FIGTripsAndEvents:View'];
export const StructuredBusinessReportPermissions = ['Reports:StructuredBusiness'];
export const CompareLobPermissions = {
  annuity: [...LumaPermissions,...AnnuityRateWatchPermissions],
  life: [...TermLifeQuotePermissions,...EnsightPermissions],
  ltc: [...LctiQuotePermissions,...CareMatterQuotePermissions],
  notes: [...LumaPermissions, ...StructuredMarketplacePermissions],
  allLob: [...LumaPermissions, ...AnnuityRateWatchPermissions, ...TermLifeQuotePermissions, ...EnsightPermissions, ...LctiQuotePermissions,...CareMatterQuotePermissions, ...LumaPermissions]
};
export const CpcReportingPermissions = ['AgentCPCs:View'];
export const DolViewPermissions = ['Dol:view'];
export const WinflexPermissions = ['Winflex:View'];
export const AdvancedMarketToolsPermissions = ['AdvancedMarketTools:View'];
export const AbacusLifeSettlementsPermissions = ['AbacusLifeSettlements:View'];
export const EventsPermissions = ['Events:View'];
export const CoreCommissionsPermissions = ['CoreCommissions:Access'];

