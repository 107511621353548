import { AfterViewInit, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fadeIn } from 'src/app/animations';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { MessagePanelConfigModel } from 'src/app/shared/components/message-notification/message-notification.component';
import { PaperAppUploadClickTargetAmplitudeEnum, PaperAppUploadStepAmplitudeEnum } from 'src/app/shared/enums/amplitude.enum';
import { PaperAppUploadAmplitudeModel } from 'src/app/shared/models/amplitude.models';
import { PaperApplicationMatDialogData } from 'src/app/shared/models/paper-application.model';
import { environment } from 'src/environments/environment';

@Component({
  animations: [fadeIn],
  selector: 'app-submit-paper-application-v2',
  templateUrl: './submit-paper-application-v2.component.html',
  standalone: false
})
export class SubmitPaperApplicationV2Component implements AfterViewInit, OnInit {

  paperAppForm = this.fb.group({
    Notes: this.fb.control<string>(''),
  });
  isSubmitting = false;
  isUploading = false;
  isSuccess = false;
  isError = false;
  isLoading = false;
  uploadingFiles: File[] = [];
  dragover = false;
  removedAFile = false;
  // TODO: Remove env production once we switch to E-App workflow V2
  panelConfig: MessagePanelConfigModel = {
    type: environment.production ? 'info' : 'alert'
  };
  // TODO: Remove env production once we switch to E-A[p workflow V2
  workflowMessage = environment.production ? 'You can also upload paper applications right from the Dashboard.' : 'This product only suports paper application. You may also upload right from the Dashboard.';

  constructor(
    private dialogRef: MatDialogRef<SubmitPaperApplicationV2Component, boolean>,
    private fb: FormBuilder,
    private reportsApiService: ReportsApiService,
    private snackbarService: SnackbarService,
    private amplitudeEventService: AmplitudeEventService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: PaperApplicationMatDialogData,
  ) { }

  ngOnInit(): void {
    this.uploadingFiles = this.data?.files || [];
    this.isLoading = false;
    this.logAmplitudeOnInit();
    this.logAmplitudeAfterClosed();
  }

  ngAfterViewInit(): void {
    const width = '60rem';
    const height = '70rem';
    this.dialogRef.updateSize(width, height);
  }

  /**
   * Infer if there are files, then user used drag-drop area, otherwise, used button
   */
  logAmplitudeOnInit(): void {
    if (this.uploadingFiles.length) this.logAmplitude(PaperAppUploadStepAmplitudeEnum.openModal, PaperAppUploadClickTargetAmplitudeEnum.dashboardDragDrop, this.uploadingFiles.length);
    else this.logAmplitude(PaperAppUploadStepAmplitudeEnum.openModal, PaperAppUploadClickTargetAmplitudeEnum.dashboardButton, 0);
  }

  /**
   * Kinda workaround to check when user click outside dialog to close whether or not they have successfully submitted an app so we can track in Amplitude
   */
  logAmplitudeAfterClosed(): void {
    this.dialogRef.backdropClick().subscribe({
      next: () => {
        this.dialogRef.close(this.isSuccess);
      }
    });
    this.dialogRef.afterClosed().subscribe({
      next: res => {
        if (!res) this.logAmplitude(PaperAppUploadStepAmplitudeEnum.userAbort, PaperAppUploadClickTargetAmplitudeEnum.close, this.uploadingFiles.length);
      }
    });
  }

  dragOverHandler(event: DragEvent): void {
    this.dragover = true;
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * Log the submit paper app funnel in Amplitude
   * @param step log which step in the funnel user is on
   * @param clickTarget log where user clicks on
   * @param fileCount number of files
   */
  logAmplitude(step: PaperAppUploadStepAmplitudeEnum, clickTarget: PaperAppUploadClickTargetAmplitudeEnum, fileCount: number): void {
    const amplitudeModel: PaperAppUploadAmplitudeModel = {
      step: step,
      click_target: clickTarget,
      file_count: fileCount,
    };
    this.amplitudeEventService.logPaperAppUpload(amplitudeModel);
  }

  /**
   * borrowed from logo upload
  */
  isValidFile(file: File): { isSuccess:boolean, errorType: 'size' | 'ext' | null } {
    const fileName: string = file.name;
    const fileSize: number = file.size;
    const idxDot = fileName.lastIndexOf('.') + 1;
    const extFile = fileName.substring(idxDot, fileName.length).toLowerCase();
    const extCheck = (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png' || extFile === 'pdf');
    const sizeCheck = fileSize <= 30000000;

    return {
      isSuccess: extCheck && sizeCheck,
      errorType: !extCheck ? 'ext' : !sizeCheck ? 'size' : null,
    };
  }

  onSubmit(form: FormGroup<PaperApp>): void {
    this.isSubmitting = true;
    const formData = new FormData();
    formData.append('Notes', form.value.Notes ?? '');

    this.uploadingFiles.forEach(function (file) {
      formData.append('File', file, file.name);
    });
    this.reportsApiService.postPaperApplicationV2(formData)
      .subscribe({
        next: () => {
          this.logAmplitude(PaperAppUploadStepAmplitudeEnum.uploadSuccess, PaperAppUploadClickTargetAmplitudeEnum.submit, this.uploadingFiles.length);
          this.isSubmitting = false;
          this.isSuccess = true;
        },
        error: () => {
          this.logAmplitude(PaperAppUploadStepAmplitudeEnum.uploadFail, PaperAppUploadClickTargetAmplitudeEnum.submit, this.uploadingFiles.length);
          this.isSubmitting = false;
          this.isError = true;
        }
      });
  }

  removeFile(file: File): void {
    const index = this.uploadingFiles.indexOf(file);
    this.uploadingFiles.splice(index, 1);
    this.removedAFile = true;
  }

  reset(): void {
    this.paperAppForm.reset();
    this.isLoading = true;
    this.uploadingFiles = [];
    this.isSubmitting = false;
    this.isUploading = false;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;
    // Log a new funnel sequence if user clears out form
    this.logAmplitude(PaperAppUploadStepAmplitudeEnum.resetForm, PaperAppUploadClickTargetAmplitudeEnum.other, 0);
  }

  uploadFiles(event: Event): void {
    this.dragover = false;
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;

    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].size >= 1 && this.isValidFile(fileList[i]).isSuccess) {
          this.uploadingFiles.push(fileList[i]);
        } else {
          let message = '';
          if (fileList[i].size < 1) message = 'File cannot be empty.';
          if (this.isValidFile(fileList[i]).errorType === 'size') message = 'File size must be smaller than 30mb.';
          if (this.isValidFile(fileList[i]).errorType === 'ext') message = 'File must be a valid file type.';
          this.snackbarService.openSnackbar(message, ToastClassEnum.warning);
        }
      }
    }
  }
}

export interface PaperApp {
  Notes: FormControl<string|null>
}