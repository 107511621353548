import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { fadeIn } from 'src/app/animations';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { PolicyDetail, PolicyFunding, PolicyTask } from 'src/app/shared/models/policy.models';
import { environment } from 'src/environments/environment';
import { BusinessRouteParamReportTypeEnum } from '../../enums/policy-v2.enums';

@Component({
  animations: [fadeIn],
  selector: 'app-policy-details-v2',
  templateUrl: './policy-details-v2.component.html',
  standalone: false
})
export class PolicyDetailsV2Component implements OnInit {
  environment = environment;
  loading = false;
  showOldView = false;
  inforceStageId = 7;
  /** We can prolly use this to determine which UI to show in the future when this component handles more than just Life/LTC stuff */
  reportType?: BusinessRouteParamReportTypeEnum | null;
  reportTypeEnum = BusinessRouteParamReportTypeEnum;
  /** null | undefined is caused by route params type definition */
  policyGuid?: string | null;
  policyDetails?: PolicyDetail;
  policyFundings?: PolicyFunding[];
  policyTasks?: PolicyTask[];
  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private reportsApiService: ReportsApiService,
    private title: Title,
  ) { }

  ngOnInit(): void {
    this.getParams();
  }

  setPageTitle(policy: PolicyDetail): void {
    this.title.setTitle(`${policy.Carrier} - ${policy.Product} | Agent Portal`);
  }

  goBack(): void {
    if (this.policyDetails?.StageHistory.find(x => x.PolicyStageId === this.inforceStageId)) {
      this.router.navigate(['../../Inforce'], {relativeTo: this.activateRoute});
    } else {
      this.router.navigate(['../../Pending'], {relativeTo: this.activateRoute});
    }
  }

  redirectToCorrectDetailsView(policy?: PolicyDetail, reportType?: BusinessRouteParamReportTypeEnum | null): void {
    if (reportType && policy) {
      if (!policy?.HasChecklistItem) this.router.navigate(['/Portal/Business/' + reportType + '/PolicyDetails/' + this.policyGuid]);
      else this.router.navigate(['/Portal/Business/' + reportType + '/PolicyDetailsV2/' + this.policyGuid]);
    }
  }

  getParams(): void {
    this.activateRoute.paramMap.subscribe({
      next: params => {
        this.policyGuid = params.get('policyGuid');
        this.reportType = params.get('reportType') as BusinessRouteParamReportTypeEnum;
        if (this.policyGuid) {
          this.getPolicyDetails(this.policyGuid);
        }
      }
    });
  }

  getPolicyDetails(policyGuid: string): void {
    this.loading = true;

    const policyDetailsHttp = this.reportsApiService.getPolicyDetailsByGuid(policyGuid).pipe(catchError(error => {
      throw error;
    }));
    const policyFundingsHttp = this.reportsApiService.getPolicyFundingByGuid(policyGuid).pipe(catchError(error => {
      throw error;
    }));
    const suitabilityHttp = this.reportsApiService.getPolicySuitabilityStatusByGuid(policyGuid).pipe(catchError(error => {
      throw error;
    }));

    const policyTasksHttp = this.reportsApiService.getPolicyRequirementHistoryByGuid(policyGuid).pipe(catchError(error => {
      throw error;
    }));

    forkJoin({
      policyDetails: policyDetailsHttp,
      policyFundings: policyFundingsHttp,
      suitability: suitabilityHttp,
      policyTasks: policyTasksHttp,
    })
      .subscribe({
        next: res => {
          this.redirectToCorrectDetailsView(res.policyDetails, this.reportType);
          this.policyDetails = res.policyDetails;
          this.policyFundings = res.policyFundings;
          this.policyTasks = res.policyTasks;
          this.setPageTitle(this.policyDetails);
        }
      })
      .add(() => {
        this.loading = false;
      });
  }
}
