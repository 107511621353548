import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { AgentMessageTaskId } from '../../enums/policy-v2.enums';
import { AgentMessageFormGroup, AgentMessageFormValue, FileUploadInfoModel, TaskNoteV2 } from '../../models/policy-v2.models';

@Component({
  selector: 'app-agent-message-input',
  templateUrl: './agent-message-input.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `
  ],
  standalone: false
})
export class AgentMessageInputComponent implements OnInit, OnDestroy {
  defaultTasksEnum = AgentMessageTaskId;
  @Output() formSubmitEvent: EventEmitter<TaskNoteV2> = new EventEmitter<TaskNoteV2>();
  @Input() policyTaskId?: number;
  @Input() policyGuid?: string;
  filesSubscription = new Subscription();
  // Angular reactive form doesn't really play nice with files. You'll notice there's no formControlName on the template
  // However, there's still some benefits to using file upload w/ reactive forms as it allows built-in validation and value accessors
  agentForm = this.fb.group({
    notes: ['', Validators.required],
    files: [[]],
  }) as AgentMessageFormGroup;
  snackbar = {
    success: 'Message sent successfully!',
    error: 'There was an issue sending your message. Please try again or contact our spport if the issue persists.',
  };
  formSubmitting = false;

  constructor(
    private snackbarService: SnackbarService,
    private fb: UntypedFormBuilder,
    private reportsApiService: ReportsApiService,
  ) { }

  ngOnInit(): void {
    this.setDynamicValidation();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  clearSubscriptions(): void {
    this.filesSubscription.unsubscribe();
  }

  setDynamicValidation(): void {
    this.filesSubscription = this.agentForm.controls.files.valueChanges.subscribe({
      next: (res: string) => {
        if (res) {
          this.agentForm.controls.notes.clearValidators();
        } else {
          this.agentForm.controls.notes.setValidators(Validators.required);
        }

        this.agentForm.controls.notes.updateValueAndValidity();
      }
    });
  }

  onFileUpload(event: Event): void {
    if (!event || !event.target) return;
    const fileList: FileList | null = (<HTMLInputElement>event.target).files;
    const files: File[] = this.agentForm.value?.files ?? [];
    if (fileList) {
      // event.target.files return a FileList obj, which is not compatible with array methods
      // Using for loop to iterate through FileList obj structure
      for (let index = 0; index < fileList.length; index++) {
        files.push(fileList[index]);
      }
    }

    // Clear input field so that files with same name can be uploaded at the same time
    (<HTMLInputElement>event.target).value = '';

    this.agentForm.controls.files.patchValue(files);
  }

  removeFile(files: File[] | null, index: number): void {
    if (!files) return;
    files.splice(index, 1);
    this.agentForm.controls.files.patchValue(files);
  }

  onSubmit(): void {
    if (this.agentForm.invalid || !this.policyGuid) {
      this.agentForm.markAllAsTouched();
    } else {
      this.formSubmitting = true;
      const formValue = this.agentForm.value;
      // to send file, require policyGuid & files
      // TODO: I suspect the policyGuid requirement is a remnant of when files can only be tied to a policy level. This should go away to make things more consistent
      if (this.agentForm.value.files?.length) {
        this.sendFilesAndNotesToArc(formValue, this.policyGuid, this.policyTaskId);
      } else {
        this.sendNotesOnlyToArc(formValue, this.policyGuid, this.policyTaskId);
      }
    }
  }

  sendFilesAndNotesToArc(formValue: AgentMessageFormValue, policyGuid: string, policyTaskId?: number): void {
    if (formValue.files?.length) {
      const formData = new FormData();
      const formMessage = formValue.notes ?? 'Agent Upload:';
      formValue.files?.forEach(file => {
        formData.append(formMessage, file, file.name);
      });
      this.reportsApiService.postPolicyFiles(policyGuid, formData, policyTaskId)
        .subscribe({
          next: (fileUploads) => {
            this.emitUpdates(formMessage, fileUploads);
            this.snackbarService.openSnackbar(this.snackbar.success, ToastClassEnum.success);
            this.agentForm.reset();
          },
          error: () => {
            this.snackbarService.openSnackbar(this.snackbar.error, ToastClassEnum.warning);
          }
        }).add(() => {
          this.formSubmitting = false;
        });
    }
  }

  sendNotesOnlyToArc(formValue: AgentMessageFormValue, policyGuid: string, policyTaskId?: number): void {
    if (formValue.notes) {
      this.reportsApiService.postPolicyNotes(formValue.notes, policyGuid, policyTaskId)
        .subscribe({
          next: () => {
            this.emitUpdates(formValue.notes);
            this.snackbarService.openSnackbar(this.snackbar.success, ToastClassEnum.success);
            this.agentForm.reset();
          },
          error: () => {
            this.snackbarService.openSnackbar(this.snackbar.error, ToastClassEnum.warning);
          }
        }).add(() => {
          this.formSubmitting = false;
        });
    }
  }


  emitUpdates(noteText: string | null, files: FileUploadInfoModel[] = []): void {
    const newFiles = files.map(file => {
      return {
        Id: file.FileId,
        FileName: file.FileName,
      };
    }) || [];
    const now = new Date().toISOString();
    const note: TaskNoteV2 = {
      NoteId: -1,
      Note: noteText ?? '',
      CreatedBy: 'System',
      CreatedOn: now,
      ModifiedOn: now,
      IsExternalUser: true,
      ReceivedByOperations: false,
      Type: null,
      Date: null,
      Files: newFiles,
      IsNew: true,
    };
    this.formSubmitEvent.emit(note);

  }
}