<div class="flex w-full h-full p-30">
  <div class="flex flex-col items-center m-auto text-xl gap-y-30">
    <p class="text-red">An error occurred while authenticating with Core Commissions, please contact our support team below.</p>
    <button mat-button type="button" class="button is-primary is-inverted" [amplitudeEvent]="'Core Commissions Authenticate: Contact Support'"
            appCyData="contactSupportBtn"
            (click)="openSupportModal()">
      <span class="flex items-center justify-start w-full">
        <span class="mr-10 mi-18 material-icons">email</span>
        <span>Contact Support</span>
      </span>
    </button>
  </div>
</div>

