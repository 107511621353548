import { Component, OnInit, Inject, AfterViewInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnrollmentFormApiService } from 'src/app/core/services/http/enrollment-form-api.service';
import { SurveyRenewalModel } from 'src/app/shared/models/surveyRenewal.models';
import { PurchaseApiService } from 'src/app/core/services/http/purchase-api.service';
import { AgreementsApiService } from 'src/app/core/services/http/agreements-api.service';
import { SurveyPurchaseModel } from 'src/app/shared/models/surveyMarketingStrategy.models';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fadeIn } from 'src/app/animations';
import { ConfirmationData } from 'src/app/shared/models/modal-data.models';
import { renewalStatusEnum } from 'src/app/shared/enums/renewal-status.enum';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { User } from 'src/app/shared/models/user.models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-renewal-form',
  templateUrl: './renewal-form.component.html',
  animations: [fadeIn],
  standalone: false
})
export class RenewalFormComponent implements OnInit, AfterViewInit {
  @Input() formParametersComponent?: RenewalFormParameters;
  surveyRenewals!: SurveyRenewalModel[];
  filterBySurveyId!: number | null;
  showCompletedSection?: boolean;
  purchaseModel!: SurveyPurchaseModel;
  showPaymentSection?: boolean;
  renewalStatus: typeof renewalStatusEnum = renewalStatusEnum;
  user: User = this.sessionStoreService.User;
  onBehalfOfUser: User | null = this.sessionStoreService.OnBehalfOfUser;

  constructor(
    private dialog: MatDialog,
    private confirmationDialog: MatDialogRef<ConfirmationComponent>,
    private renewalDialog: MatDialogRef<RenewalFormComponent>,
    private enrollmentFormApiService: EnrollmentFormApiService,
    @Inject(MAT_DIALOG_DATA) private formParameters: RenewalFormParameters,
    private purchaseApiService: PurchaseApiService,
    private agreementsApiService: AgreementsApiService,
    private _snackBar: MatSnackBar,
    private sessionStoreService: SessionStoreService,
  ) {
  }

  ngAfterViewInit(): void {
    this.confirmationDialog.updateSize('72rem');
  }

  ngOnInit(): void {
    if (this.formParametersComponent) {
      this.filterBySurveyId = this.formParametersComponent.FilterBySurveyId;
      this.surveyRenewals = this.formParametersComponent.SurveyRenewals;
    } else {
      this.filterBySurveyId = this.formParameters.FilterBySurveyId;
      this.surveyRenewals = this.formParameters.SurveyRenewals;
    }
  }

  closeDialog(): void {
    this.ignoreAllRenewals();
    this.renewalDialog.close();
  }

  ignoreAllRenewals(): void {
    this.surveyRenewals.forEach(s => {
      if (s.SurveyRenewalStatusId === renewalStatusEnum.Open) {
        this.setSurveyRenewalAsIgnored(s);
      }
    });
  }

  setSurveyRenewalAsIgnored(survey: SurveyRenewalModel): void {
    this.enrollmentFormApiService.surveyRenewalIgnored(survey.SurveyRenewalId as number).subscribe(
      () => {
        this.refreshSurveyRenewalList();
        survey.RenewModifiedOn = new Date();
        survey.RenewalStatus = 'Ignored';
        survey.ActionTaken = true;
      });
  }

  refreshSurveyRenewalList(): void {
    this.enrollmentFormApiService.getSurveyRenewals(this.filterBySurveyId).subscribe(results => {
      this.surveyRenewals = results;
      const openSurveys = results.some(s => s.SurveyRenewalStatusId === renewalStatusEnum.Open);
      if (!openSurveys) {
        this.showCompletedSection = true;
        this.confirmationDialog.disableClose = false;
      } else {
        this.surveyRenewals = results;
        this.confirmationDialog.disableClose = true;
      }
    });
  }

  payComponentCallback(callback: any): void {
    if (callback.result.surveyRenewalId) {
      this.showPaymentSection = false;
      const renewalModel = this.surveyRenewals.find(s => s.SurveyRenewalId === callback.result.surveyRenewalId);
      if (!renewalModel) return; // check to see if it was found - typescript always keeping me honest

      renewalModel.ActionTaken = false;

      if (callback.result.orderId) {

        renewalModel.OrderId = callback.result.orderId;

        //set interval to poll SurveyRenewal that is awaiting payment to complete
        const intervalPromise = setInterval(() => {

          this.enrollmentFormApiService.getSurveyRenewalByOrderId(callback.result.orderId).subscribe((orderSurvey) => {

            if (orderSurvey.OrderComplete) {
              renewalModel.OrderComplete = true;
              clearInterval(intervalPromise);
              if (renewalModel.MarketingStrategy.DocusignApiTemplate !== null && renewalModel.MarketingStrategy.DocusignApiTemplate.RenewalDocusignDocumentId.length) {
                this.openSignatureModal(renewalModel);
              }
            }
          }, () => {
            clearInterval(intervalPromise);
            this._snackBar.open('We\'re sorry, there was an error; Please reload this page and try again.', 'Close', {
              panelClass: ['is-error']
            });
          });
        }, 1000);
      }
    }
  }

  setSurveyRenewalAsAccepted(survey: SurveyRenewalModel): void {
    if (survey.MarketingStrategy.PurchaseModel !== null) {
      this.purchaseModel = survey.MarketingStrategy.PurchaseModel;
      //add items to build order model in order page component
      this.purchaseApiService.getMarketingDollars().subscribe(response => {
        this.purchaseModel.AgentMarketingDollars = response.Entry !== null ? response.Entry.MarketingDollarsTotal : 0;
      }, () => { });
      this.purchaseModel.MarketingStrategyName = survey.MarketingStrategy.MarketingStrategy;
      this.purchaseModel.ArcSurveyRenewalId = survey.SurveyRenewalId;
      this.showPaymentSection = true;
      survey.ActionTaken = true;
    } else {
      /* Need to get new docusign link and open it */
      if (survey.MarketingStrategy.DocusignApiTemplate !== null && survey.MarketingStrategy.DocusignApiTemplate.RenewalDocusignDocumentId.length) {
        this.openSignatureModal(survey);
      } else {
        survey.RenewalStatus = 'Accepted';
        this.enrollmentFormApiService.surveyRenewalAccepted(survey.SurveyRenewalId as number, renewalStatusEnum.Accepted).subscribe(() => {
          this.refreshSurveyRenewalList();
          survey.ActionTaken = true;
        });
      }
    }
  }

  openSignatureModal(survey: SurveyRenewalModel): void {
    this.agreementsApiService.getSurveyAgreementRenewalDocusignLink(survey.SurveyId, survey.SurveyRenewalId as number).subscribe(redirectUrl => {
      const dialogRef = this.dialog.open<ConfirmationComponent, ConfirmationData>(ConfirmationComponent, {
        data: {
          title: survey.Name,
          message: survey.Name + ' requires you to sign a renewal agreement, would you like to sign now?',
          confirmationButtonText: 'Go to Signing',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.addSurveyRenewalEvent(survey, 'Docusign Agreement Link Opened');
          window.open(redirectUrl);
          this.pollSurveyForCompleteStatus(survey.SurveyRenewalId as number);
          survey.ActionTaken = true;
        }
      });
    }, () => {
      this._snackBar.open('We\'re sorry, there was an error; Please reload this page and try again.', 'Close', {
        panelClass: ['is-error']
      });
    });
  }

  addSurveyRenewalEvent(survey: SurveyRenewalModel, eventText: string): Observable<any> {
    return this.enrollmentFormApiService.createSurveyRenewalEvent(survey.SurveyRenewalId as number, eventText);
  }

  pollSurveyForCompleteStatus(surveyRenewalId: number): void {
    const intervalPromise = setInterval(() => {

      this.enrollmentFormApiService.getSurveyRenewalBySurveyRenewalId(surveyRenewalId).subscribe(
        (survey) => {
          if (survey.SurveyRenewalStatusId === renewalStatusEnum.Ignored || survey.SurveyRenewalStatusId === renewalStatusEnum.Declined) {
            this.refreshSurveyRenewalList();
            clearInterval(intervalPromise);
          }
        },
        (error) => {
          console.log(error);
          clearInterval(intervalPromise);
        });
    }, 1000);
  }

  openDeclinedModal(survey: SurveyRenewalModel): void {
    const dialogRef = this.dialog.open<ConfirmationComponent, ConfirmationData>(ConfirmationComponent, {
      data: {
        title: survey.Name,
        message: 'Are you sure you do NOT want to renew ' + survey.Name + '?',
        confirmationButtonText: 'Decline',
        isWarning: true
      }
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        survey.ActionTaken = true;
        this.setSurveyRenewalAsDeclined(survey);
      } else {
        survey.ActionTaken = false;
      }
    });
  }

  setSurveyRenewalAsDeclined(survey: SurveyRenewalModel): void {
    survey.RenewalStatus = 'Declined';
    this.enrollmentFormApiService.surveyRenewalDeclined(survey.SurveyRenewalId as number, renewalStatusEnum.Declined).subscribe(
      () => {
        this.refreshSurveyRenewalList();
      },
      () => {
        survey.ActionTaken = false;
        this._snackBar.open('We\'re sorry, there was an error; Please reload this page and try again.', 'Close', {
          panelClass: ['is-error']
        });
      });
  }
}

export interface RenewalFormParameters {
  SurveyRenewals: SurveyRenewalModel[];
  FilterBySurveyId: number | null;
}