import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { tap, filter, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ContactApiService } from 'src/app/core/services/http/contact-api.service';
import { Contact } from '../../models/contact.models';
import { ManageIntegrationsEnum } from '../../enums/integrations.enum';

@Component({
  selector: 'app-contact-search-form',
  templateUrl: './contact-search-form.component.html',
  styleUrls: ['./contact-search-form.component.scss'],
  standalone: false
})

export class ContactSearchFormComponent implements OnInit {
  @Output() switchToManualClientInput = new EventEmitter<boolean>();
  @Output() contactSelected = new EventEmitter<Contact>();
  @Input() parentCtrl: FormControl<Contact | string | null | undefined> = this.fb.control<Contact | string | null | undefined>(null);
  @Input() connectedCrms: ManageIntegrationsEnum[] = [];
  isSearching = false;
  hasError = false;
  contacts: Contact[] = [];
  isContact = false;
  noContactFound = false;

  constructor(
    private fb: FormBuilder,
    private contactApiService: ContactApiService
  ) { }

  ngOnInit(): void {
    // W/e the input value changes in the search box
    this.searchContact();
  }

  searchContact(): void {
    this.parentCtrl.valueChanges.pipe(
      tap(value => {
        this.hasError = false;
        this.checkIsContact(value);
      }),
      debounceTime(350),
      map(value => {
        return value && typeof value === 'string' ? value : '';
      }),
      filter(searchText => {
        // We don't want to search if input is less than 3, because some of the crms will not accecpt searches less than 3 chars
        if (!searchText || searchText.length < 3) {
          this.isSearching = false;

          return false;
        }
        return true;
      }),
      distinctUntilChanged()
    ).subscribe({
      next: value => {
        this.isSearching = true;
        this.contacts = [];
        this.contactApiService.searchContacts({ Name: value, Crms: this.connectedCrms }).subscribe({
          next: contacts => {
            this.contacts = contacts;
            if (!contacts.length) {
              this.noContactFound = true;
            } else {
              this.noContactFound = false;
            }
          },
          error: () => {
            this.hasError = true;
            this.noContactFound = false;
            this.contacts = [];
          }
        }).add(() => this.isSearching = false);
      }
    });
  }
  setDisplayValue(contact: Contact | string | null): string {
    if (contact && typeof contact !== 'string') {
      if (contact.FullName && contact.FullName !== '') {
        return contact.FullName;
      } else if (contact.CompanyName && contact.CompanyName !== '') {
        return contact.CompanyName;
      }
      return contact.FirstName + ' ' + contact.LastName;
    }
    return '';
  }

  checkIsContact(value: Contact | string | null | undefined): void {
    if (!value || typeof value === 'string') {
      this.isContact = false;
      return;
    } else {
      this.isContact = true;
    }
  }

  requestManualInput(): void {
    this.parentCtrl.disable();
    this.switchToManualClientInput.next(true);
  }
}