import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fadeIn } from 'src/app/animations';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { InformalPolicyV2, InformalSubCategory } from 'src/app/shared/models/policy.models';
import { BusinessRouteParamReportTypeEnum, InformalTaskStatusEnum } from '../../enums/policy-v2.enums';
// import groupBy from 'lodash-es/groupBy';

@Component({
  animations: [fadeIn],
  selector: 'app-informal-policy-details-v2',
  templateUrl: './informal-policy-details-v2.component.html',
  styles: [`
    :host {
        display: block;
        width:100%;
      }
  `],
  standalone: false
})
export class InformalPolicyDetailsV2Component implements OnInit {
  subcategoryTitles: string[] = [];
  subcategories: InformalSubCategory[] = [];
  informalGuid: string | null = null;
  informalPolicy?: InformalPolicyV2;
  isLoading = false;
  reportType: string | null = null;
  showCompleted = false;
  InformalTaskStatusEnum = InformalTaskStatusEnum;
  constructor(
    private activateRoute: ActivatedRoute,
    private reportsApi: ReportsApiService
  ) { }

  ngOnInit(): void {
    this.getParams();
  }

  filterCategories(): void {
    this.subcategories = [];
    const categories = [... new Set(this.informalPolicy?.TaskInformation.map(t => t.TaskSubcategory))];
    categories.forEach(name => {
      if (!this.showCompleted) {
        const group = this.informalPolicy?.TaskInformation.filter(req => req.TaskSubcategory === name && req.TaskStatus !== InformalTaskStatusEnum.Complete);
        this.subcategories.push({ Name: name, InformalRequirements: group ? group : [] });
      } else {
        const group = this.informalPolicy?.TaskInformation.filter(req => req.TaskSubcategory === name);
        this.subcategories.push({ Name: name, InformalRequirements: group ? group : [] });
      }
    });
  }

  getInformalPolicyDetailsV2(guid: string): void {
    this.isLoading = true;
    this.reportsApi.getInformalDetailV2(guid).subscribe({
      next: policyDetails => {
        this.informalPolicy = policyDetails;
        console.log(policyDetails);
        this.filterCategories();
      }, error: () => {
        console.log('error retrieving informal details');
      }
    }).add(() => this.isLoading = false);
  }

  getParams(): void {
    this.activateRoute.paramMap.subscribe({
      next: params => {
        this.informalGuid = params.get('policyGuid');
        this.reportType = params.get('reportType') as BusinessRouteParamReportTypeEnum;
        if (this.informalGuid) {
          this.getInformalPolicyDetailsV2(this.informalGuid);
        }
      }
    });
  }
}
