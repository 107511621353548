import { Component, Input, OnInit } from '@angular/core';
import { fadeIn, fadeInOut } from 'src/app/animations';

@Component({
  selector: 'app-message-notification',
  templateUrl: './message-notification.component.html',
  animations: [fadeIn, fadeInOut],
  styleUrls: ['./message-notification.component.scss'],
  standalone: false
})
/** This can be extended and reused for more complex scenarios. Ex: Self-remove after set time, dismissable, etc. */
export class MessageNotificationPanelComponent implements OnInit {
  private classList = {
    info: 'border-blue-lighter bg-blue-lightest text-gray-dark',
    alert: 'border-orange-lighter bg-orange-lightest text-orange',
    error: 'border-red-lighter bg-red-lightest text-red',
  };
  private iconList = {
    info: 'info',
    alert: 'warning',
    error: 'warning'
  };
  iconClass = '';
  panelIcon = '';
  panelClass = '';
  @Input() panelConfig: MessagePanelConfigModel = {
    type: 'info',
  };

  ngOnInit(): void {
    this.setPanelContent();
  }

  private setPanelContent(): void {
    switch (this.panelConfig.type) {
      case 'alert':
        this.iconClass = 'text-orange';
        this.panelClass = this.classList.alert;
        this.panelIcon = this.iconList.alert;
        break;

      case 'error':
        this.iconClass = 'text-red';
        this.panelClass = this.classList.error;
        this.panelIcon = this.iconList.error;
        break;

      default:
        this.iconClass = 'text-blue-light';
        this.panelClass = this.classList.info;
        this.panelIcon = this.iconList.info;
        break;
    }
  }
}
/**
 * @param type Optional: generates colors & icon in order of ascending urgency - blue, orange, red. Defaults to 'info
 * @param panelClass Optional: custom styling CSS classes. WARNING: This will replace the default classes like border, background & text color
 */
export interface MessagePanelConfigModel {
  /** Optional: generates colors & icon in order of ascending urgency - blue, orange, red. Defaults to 'info' */
  type?: 'info' | 'alert' | 'error';
  /** Optional: custom styling CSS classes. WARNING: This will replace the default classes like border, background & text color */
  panelClass?: string;
}