import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Table, TableHeader } from 'src/app/shared/classes/table';
import { RequirementsComponent } from '../../../requirements/requirements.component';
import { PendingPolicyTableHeaders } from '../../constants/pendingPolicyTableHeaders.constants';
import { PendingTablePolicy } from '../../models/pendingTablePolicy.model';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { fadeIn } from 'src/app/animations';
import { PolicyRequirementsModalV2Component } from '../../../../modals/policy-requirements-modal-v2/policy-requirements-modal-v2.component';
import { RequirementModelInputData } from '../../../../models/policy-v2.models';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AmplitudeEventV2Service } from 'src/app/core/services/amplitude/amplitude-event-v2.service';


@Component({
  animations: [fadeIn],
  selector: 'app-pending-policy-table',
  templateUrl: './pending-policy-table.component.html',
  standalone: false
})
export class PendingPolicyTableComponent implements OnInit, OnChanges {
  @ViewChild(CdkVirtualScrollViewport) virtualScroll?: CdkVirtualScrollViewport;
  @Input() pendingPolicies: PendingTablePolicy[] = [];
  @Input() selectedAgentIds: string[] = [];
  table: Table<PendingTablePolicy> = new Table<PendingTablePolicy>(PendingPolicyTableHeaders, 1, true);
  @Input() isError = false;
  loadingModal = false;
  reportType = '';
  showAgentColumn = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private amplitudeEventV2Service: AmplitudeEventV2Service,
    private dialog: MatDialog,
    private router: Router,
    private sessionStore: SessionStoreService,) { }

  ngOnInit(): void {
    this.reportType = this.activatedRoute.snapshot.data['reportType'] as string;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pendingPolicies']?.previousValue !== changes['pendingPolicies']?.currentValue) {
      this.table.setBody(this.pendingPolicies);
    }
    this.toggleAgentColumn();
  }

  /**
   * Go to policy details v1 or v2 depending on the type
   */
  openPolicyDetails(row: PendingTablePolicy): void {
    if (this.loadingModal) return;
    // Life & LTC are the only 2 that have checklist item
    if (row.HasChecklistItem) {
      this.router.navigate(['/Portal/Business/' + this.reportType + '/PolicyDetailsV2/' + row.PolicyGuid]);
    } else this.router.navigate(['/Portal/Business/' + this.reportType + '/PolicyDetails/' + row.PolicyGuid]);

    const eventObj: AmplitudePolicyEventModel = {
      policyLobId: row.LineOfBusinessId,
      policyActionNeeded: row.ActionNeeded,
      policyStatus: row.PolicyStatus,
      policyIsVariable: row.IsVariable,
      policyHasCheckList: row.HasChecklistItem,
    };
    this.amplitudeEventV2Service.logClickEvent('Go to Pending Pol Details', eventObj);
  }

  openRequirementModal(row: PendingTablePolicy): void {
    this.loadingModal = true;
    //TODO: This should be temporary logic since it's not intuitive to devs as to why it should open 2 different modal types
    if (row.HasChecklistItem) {
      this.openNewRequirementChecklistModal(row);
    } else {
      this.openOldRequirementModal(row);
    }
    const eventObj: AmplitudePolicyEventModel = {
      policyLobId: row.LineOfBusinessId,
      policyActionNeeded: row.ActionNeeded,
      policyStatus: row.PolicyStatus,
      policyIsVariable: row.IsVariable,
      policyHasCheckList: row.HasChecklistItem,
    };
    this.amplitudeEventV2Service.logClickEvent('Open Pending Pol Requirement Modal', eventObj);
  }
  /**
   * Open Life/LTC V2 UI. This may need to be updated to include other LOBs in the future
   * @param policy `PendingTablePolicy` used to retrieve checklist updates by policyGuid
   */
  openNewRequirementChecklistModal(policy: PendingTablePolicy): void {
    const data: RequirementModelInputData = {
      policyGuid: policy.PolicyGuid,
      isVariable: policy.IsVariable,
      lobId: policy.LineOfBusinessId,
      detailsUrl: `/Portal/Business/${this.reportType}/PolicyDetailsV2/${policy.PolicyGuid}`
    };
    const dialog = this.dialog.open<PolicyRequirementsModalV2Component, RequirementModelInputData>(PolicyRequirementsModalV2Component, {
      data: data,
    });

    dialog.afterClosed().subscribe({
      next: () => {
        this.loadingModal = false;
      }
    });
  }

  openOldRequirementModal(policy: PendingTablePolicy): void {
    const data: RequirementModelInputData = {
      policyGuid: policy.PolicyGuid,
      isVariable: policy.IsVariable,
      lobId: policy.LineOfBusinessId,
      requirementHeader: { Carrier: policy.Carrier, Product: policy.Product },
      detailsUrl: `/Portal/Business/${this.reportType}/PolicyDetails/${policy.PolicyGuid}`
    };
    const dialogRef = this.dialog.open<RequirementsComponent, RequirementModelInputData>(RequirementsComponent, {
      data: data,
      width: '72rem',
      height: '84rem'
    });

    dialogRef.afterClosed().subscribe({
      next: () => {
        this.loadingModal = false;
      }
    });
  }

  toggleAgentColumn(): void {
    if (this.selectedAgentIds.length === 1 && this.selectedAgentIds.includes(this.sessionStore.User.AgentArcGuid)) {
      this.table.Header = PendingPolicyTableHeaders.filter(h => h.DisplayName !== 'Agent');
      this.showAgentColumn = false;
    } else {
      this.table.Header = PendingPolicyTableHeaders;
      this.showAgentColumn = true;
    }
  }

  orderTableBy(col: TableHeader<PendingTablePolicy>): void {
    this.table.orderBy(col);
    this.virtualScroll?.scrollTo({ top: 0 });
  }
}

interface AmplitudePolicyEventModel {
  policyLobId: number,
  policyActionNeeded: boolean,
  policyStatus: string,
  policyIsVariable: boolean,
  policyHasCheckList: boolean,
}