import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { UserGroupApiService } from 'src/app/core/services/http/user-group-api.service';
import { errorMessageMap } from './constants/error-message-map.constants';
import { ErrorMessageModel } from './models/errorMessageModel';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  standalone: false
})
export class ErrorComponent implements OnInit {
  logoUrl?: string;
  figLogo = './assets/images/fig-logo.svg';
  currentYear = new Date().getFullYear();
  errorMessageModel?:ErrorMessageModel;
  constructor(private activatedRoute: ActivatedRoute, private authService: AuthService, private userGroupApiService: UserGroupApiService) { }

  ngOnInit(): void {
    const userGroupId = this.activatedRoute.snapshot.queryParamMap.get('userGroupId');
    const message = this.activatedRoute.snapshot.queryParamMap.get('msg') ?? '';
    if (userGroupId) {
      this.userGroupApiService.getLogo(userGroupId)
        .subscribe({
          next: (logoUrl) => this.logoUrl = `/api/UserGroups/${logoUrl}/Logo`,
          error: () => this.logoUrl = this.figLogo,
        });
    } else {
      this.logoUrl = this.figLogo;
    }
    this.errorMessageModel = errorMessageMap.get(message) ?? {ErrorMessage: 'An error occurred.', ShowLogIn: true};
  }

  goToLogIn(): void {
    this.authService.logIn();
  }
}
