import { Component, OnInit, signal } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { fadeIn } from 'src/app/animations';
import { RedtailApiService } from 'src/app/core/services/http/redtail-api.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { ManageIntegrationsEnum } from 'src/app/shared/enums/integrations.enum';
import { ManageIntegrationResModel } from 'src/app/shared/models/intergrations.models';
import { RedtailContact } from 'src/app/shared/models/redtail-contact.models';
import { ManageIntegrationsModalComponent } from '../../manage-integrations-modal.component';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { RedtailCrmIntegrationComponent } from './components/redtail-crm-integration/redtail-crm-integration.component';
import { ConfirmationComponent } from '../../../confirmation/confirmation.component';
import { ConfirmationData } from 'src/app/shared/models/modal-data.models';
import { RedtailEnrolleeApiService } from 'src/app/core/services/http/redtail-enrollee-api.service';

@Component({
  animations: [fadeIn],
  selector: 'app-redtail-integration',
  templateUrl: './redtail-integration.component.html',
  standalone: false
})
export class RedtailIntegrationComponent implements OnInit {

  canEnroll = false;
  flags = this.sessionStore.UserFeatureFlag;
  _isLcmConnected = signal<boolean>(false);
  isLoading = false;
  isRedtailAuthenticated = false;
  isSubmitting = false;
  redtailAuthForm = this.fb.group({
    username: this.fb.nonNullable.control('', Validators.required),
    password: this.fb.nonNullable.control('', Validators.required),
  });
  redtailContacts: RedtailContact[] = [];
  redtailFeatureFlag = this.flags.find(a => a.FeatureFlag === 'redtail_lifecycle');

  constructor(
    private amplitudeEventService: AmplitudeEventService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ManageIntegrationsModalComponent, ManageIntegrationResModel>,
    private fb: FormBuilder,
    private redtailApiService: RedtailApiService,
    private redtailEnrolleeService: RedtailEnrolleeApiService,
    private snackbarService: SnackbarService,
    private sessionStore: SessionStoreService,
  ) { }

  ngOnInit(): void {
    this.setupForm();
  }

  get usernameCtrl(): AbstractControl<string> {
    return this.redtailAuthForm.controls.username;
  }

  get passwordCtrl(): AbstractControl<string> {
    return this.redtailAuthForm.controls.password;
  }

  authenticateWithRedTail(): void {
    let message = '';
    let toastClass = ToastClassEnum.default;

    if (this.redtailAuthForm.valid) {
      this.isSubmitting = true;
      const username = this.redtailAuthForm.value.username || '';
      const password = this.redtailAuthForm.value.password || '';
      this.redtailApiService.authenticate(username, password)
        .subscribe({
          next: () => {
            this.onFormSubmission(true);
            message = 'You have successfully logged into Redtail.';
            toastClass = ToastClassEnum.success;
            this.amplitudeEventService.logCustomEvent(ManageIntegrationsEnum.Redtail, 'CRM Connection', {
              crmIsConnected: true,
            }, false);
            this.amplitudeEventService.updateUserProperties({
              redtailUser: true,
            });
            this.checkCanEnroll();
          },
          error: () => {
            message = 'There was an error logging into Redtail. Please try again later.';
            toastClass = ToastClassEnum.warning;
          }
        })
        .add(() => {
          this.snackbarService.openSnackbar(message, toastClass);
          this.isSubmitting = false;
        });
    }
  }

  /** Check if user has permission to see the Redtail Lifecycle integration button.
   * 1. Check if the feature flag is present, and user has the flag enabled
   * 2. Check if user has access to enroll
   * 3. If user does have access to enroll, check if they are already enrolled
  */
  checkCanEnroll(): void {
    if (this.redtailFeatureFlag?.FlagEnabled) {
      this.isLoading = true;
      this.redtailEnrolleeService.canEnroll()
        .subscribe({
          next: response => {
            this.canEnroll = response;
            if (response) this.checkEnrolleeStatus();
          }
        }).add(() => this.isLoading = false);
    }
  }

  /** Check if user is already enrolled in Redtail Lifecyle integration */
  checkEnrolleeStatus(): void {
    this.redtailEnrolleeService.isEnrolled()
      .subscribe({
        next: response => this._isLcmConnected.set(response)
      });
  }

  deactivateRedTail(): void {
    let message = '';
    let toastClass = ToastClassEnum.default;
    this.isSubmitting = true;
    this.redtailApiService.removeAuthentication()
      .subscribe({
        next: () => {
          this.isSubmitting = false;
          this.onFormSubmission(false);
          message = ManageIntegrationsEnum.Redtail + ' deactivated successfully!';
          toastClass = ToastClassEnum.success;
          this.amplitudeEventService.logCustomEvent(ManageIntegrationsEnum.Redtail, 'CRM Connection', {
            crmIsConnected: false,
          }, false);
          this.amplitudeEventService.updateUserProperties({
            redtailUser: false,
          });
        },
        error: () => {
          message = 'Error removing authentication.';
          toastClass = ToastClassEnum.warning;
        }
      }).add(() => {
        this.snackbarService.openSnackbar(message, toastClass);
      });
  }

  /** Remove the enrollee from the list of clients with Redtail connected to Lifecycle */
  deleteEnrollee(): void {
    let message = '';
    let toastClass = ToastClassEnum.default;
    this.redtailEnrolleeService.deleteEnrollee()
      .subscribe({
        next: () => {
          message = 'Successfully removed lifecycle connection';
          toastClass = ToastClassEnum.success;
          this.checkEnrolleeStatus();
        }, error: () => {
          message = 'There was an error removing lifecycle connection. Please try again';
          toastClass = ToastClassEnum.warning;
        }
      }).add(() => this.snackbarService.openSnackbar(message, toastClass));
  }

  onFormSubmission(isConnected: boolean): void {
    this.isRedtailAuthenticated = isConnected;
    this.sessionStore.setUserRedtailStatus(isConnected);
    if (isConnected) this.redtailAuthForm.disable();
    else this.redtailAuthForm.enable();
  }

  openConfirmationModal(): void {
    this.dialog.open<ConfirmationComponent, ConfirmationData>(ConfirmationComponent, {
      data: {
        message: this._isLcmConnected()
          ? 'Disconnecting will also remove your connection to the Lifecycle Data Redtail integration. Are you sure you want to proceed?'
          : 'Are you sure you want to disconnect your Redtail account from Agent Portal?',
        isWarning: true,
        confirmationButtonText: 'Disconnect Redtail'
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        if (this._isLcmConnected()) this.deleteEnrollee();
        this.deactivateRedTail();
      } else return;
    });
  }

  openLifecycleIntegrationModal(): void {
    this.dialog.open<RedtailCrmIntegrationComponent>(RedtailCrmIntegrationComponent, {
      data: {
      }
    }).afterClosed().subscribe(() => {
      this.checkEnrolleeStatus();
    });
  }

  setupForm(): void {
    this.isSubmitting = true;
    this.redtailApiService.isUserAuthenticated()
      .subscribe({
        next: res => {
          this.isRedtailAuthenticated = res;
          if (res) {
            this.redtailAuthForm.disable();
            this.checkCanEnroll();
          }
        },
        error: () => {
          this.isRedtailAuthenticated = false;
        }
      })
      .add(() => this.isSubmitting = false);
  }
}