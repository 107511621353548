import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { fadeIn } from 'src/app/animations';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { downloadFile } from 'src/app/shared/helpers/filedownloader.helper';
import { hasAll } from 'src/app/shared/helpers/search.helpers';
import { AgentCommissionGroupList, AgentGuidList } from 'src/app/shared/models/agent.models';
import { PolicyList, PolicyListFilter, PolicyListTypeEnum } from 'src/app/shared/models/policy.models';
import { sidenavConfig } from 'src/app/shared/models/sidenav-config.model';
import { BusinessRouteParamReportTypeEnum } from '../../enums/policy-v2.enums';
import { CpcPolicy } from './models/cpc-policy.model';
import { CpcTablePolicy } from './models/cpcPolicyTable.model';

@Component({
  animations: [fadeIn],
  selector: 'app-cpc-reporting-list',
  templateUrl: './cpc-reporting-list.component.html',
  standalone: false
})
export class CpcReportingListComponent implements OnInit, PolicyList {
  @ViewChild(MatSidenav) sidenav?: MatSidenav;
  policyListType = PolicyListTypeEnum.Cpc;
  allPolicies: CpcPolicy[] = [];
  currentFilters: PolicyListFilter = new PolicyListFilter();
  filteredPolicies: CpcPolicy[] = [];
  isError = false;
  isLoading = false;
  reportType?: BusinessRouteParamReportTypeEnum;
  searchText: string | null = null;
  sidenavConfig: sidenavConfig;

  needToQueryAgentPolicies = true;
  cpcPolicies: CpcTablePolicy[] = [];
  queryStartDate?: Date;
  queryEndDate?: Date;
  isExporting = false;
  totalPendingPremium = 0;
  totalPendingCPCs = 0;
  totalPostedPremium = 0;
  totalPostedCPCs = 0;
  routeTitle?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private amplitudeEventService: AmplitudeEventService,
    private reportsApiService: ReportsApiService,
    private snackbar: SnackbarService,
  ) {
    this.sidenavConfig = {
      IsOpened: true,
      Mode: 'side',
    };
    this.routeTitle = this.activatedRoute.snapshot.data['title'] as string | undefined;
  }

  ngOnInit(): void {
    this.reportType = this.activatedRoute.snapshot.data['reportType'] as BusinessRouteParamReportTypeEnum;
  }

  toggleSideNavMenu(): void {
    this.sidenav?.toggle();
  }

  updateReportType(): void {
    this.reportType = this.activatedRoute.snapshot.data['reportType'] as BusinessRouteParamReportTypeEnum;
  }


  // TODO: Export seems to behave differently than the othe rpages when it comes to the model being passed in. May need to investigate later? - Harry
  export(): void {
    if (this.filteredPolicies.length === 0) {
      this.snackbar.openSnackbar('Exporting policies, please wait.', ToastClassEnum.default);
    }
    this.reportsApiService.exportCpcPolicies(this.filteredPolicies).subscribe({
      next: blob => {
        downloadFile(blob, 'PolicyData.xlsx');
        this.snackbar.closeSnackbar();
      },
      error: () => {
        this.snackbar.openSnackbar('Sorry, an error has occurred. Please try again later.', ToastClassEnum.warning);
      }
    }).add(() => {
      this.isExporting = false;
    });
  }


  onFilterChange(filters: PolicyListFilter): void {
    this.currentFilters = filters;

    if (this.currentFilters.SelectedSubEntities.length > 0) {
      this.getPoliciesForSubEntities();
    } else if (this.needToQueryAgentPolicies || !this.allPolicies || this.currentFilters.IsClearingPolicies || this.needsExpandedDateQuery()) {
      //if policies haven't been loaded make api call OR if changing from subEntities to subAgents
      this.queryStartDate = this.currentFilters.StartDate;
      this.queryEndDate = this.currentFilters.EndDate;
      this.getPoliciesForAgents();
    } else {
      // else just filter existing policies
      this.setTable();
    }
  }

  setSearchText(searchText: string | null): void {
    this.searchText = searchText;
    this.setTable();
  }

  private agentFilter(policy: CpcPolicy): boolean {
    const agentIds = this.currentFilters.SelectedAgentIds;
    let match = false;
    if (agentIds && agentIds.length > 0) {
      match = agentIds.some(a => {
        return (a.replace(/[{}]/g, '').toLowerCase() === policy.PortalAgentIDGUID?.replace(/[{}]/g, '').toLowerCase());
      });
    }
    return match;
  }

  private dateFilter(value: CpcPolicy): boolean {
    if (value.StatementDate === null) return true;
    return (new Date(value.StatementDate) >= new Date(this.currentFilters.StartDate) && (new Date(value.StatementDate)) <= new Date(this.currentFilters.EndDate));
  }

  private getAgentGuidList(): AgentGuidList {
    // All agent and lob id's are being used since we are filtering on the front end
    return {
      AgentIds: this.currentFilters.AllAgentIds.join(','),
      LobIds: this.currentFilters.AllLobIds,
      StartDate: this.currentFilters.StartDate,
      EndDate: this.currentFilters.EndDate,
      IncludeUser: false,
      CanSeeAgentCommissions: false
    };
  }

  private getCommissionGroupList(): AgentCommissionGroupList {
    return {
      ParentAgentGuid: this.currentFilters.ParentAgentGuid,
      CommissionGroupIds: this.currentFilters.SelectedSubEntities.join(','),
      LobIds: this.currentFilters.AllLobIds,
      StartDate: this.currentFilters.StartDate,
      EndDate: this.currentFilters.EndDate,
      IncludeUser: false,
      CanSeeAgentCommissions: false
    };
  }

  private getFilteredPolicies(): CpcPolicy[] {
    return this.allPolicies.filter(policy => {
      return this.lobFilter(policy) && (this.currentFilters.SelectedSubEntities.length > 0 || this.agentFilter(policy)) && this.searchTextFilter(policy) && this.dateFilter(policy);
    });
  }


  private getPoliciesForSubEntities(): void {
    this.isLoading = true;
    const commissionGroupList: AgentCommissionGroupList = this.getCommissionGroupList();
    this.reportsApiService.getCommissionGroupCpcPolicies(commissionGroupList).subscribe({
      next: res => this.handleGetPoliciesSuccess(res),
      error: () => this.handelGetPoliciesFailure()
    }).add(() => {
      this.isLoading = false;
    });
  }

  private getPoliciesForAgents(): void {
    this.isLoading = true;
    const agentGuidList = this.getAgentGuidList();
    this.reportsApiService.getCpcPolicies(agentGuidList).subscribe({
      next: res => this.handleGetPoliciesSuccess(res),
      error: () => this.handelGetPoliciesFailure()
    }).add(() => {
      this.isLoading = false;
    });
  }

  private lobFilter(policy: CpcPolicy): boolean {
    let match = false;
    if (this.currentFilters.SelectedLobIds && this.currentFilters.SelectedLobIds.length > 0) {
      match = this.currentFilters.SelectedLobIds.some((lobId) => !!policy.LineOfBusinessId && lobId === policy.LineOfBusinessId);
    }
    return match;
  }

  private needsExpandedDateQuery(): boolean {
    return (this.currentFilters.StartDate < (this.queryStartDate as Date)
      || this.currentFilters.StartDate > (this.queryEndDate as Date)
      || this.currentFilters.EndDate > (this.queryEndDate as Date)
      || this.currentFilters.EndDate < (this.queryStartDate as Date));
  }

  searchTextFilter(policy: CpcPolicy): boolean {
    if (!this.searchText) return true;
    return hasAll(policy, this.searchText);
  }

  private setTable(): void {
    this.isLoading = true;

    this.filteredPolicies = this.getFilteredPolicies();
    this.cpcPolicies = this.filteredPolicies.map(p => {
      return {
        PortalAgentIDGUID: p.PortalAgentIDGUID,
        AgentName: p.AgentFirst + ' ' + p.AgentLast,
        StatementDate: p.StatementDate,
        StatementStatus: p.StatementStatus,
        LineOfBusiness: p.LineOfBusiness,
        Carrier: p.Carrier,
        PolicyNumber: p.PolicyNumber,
        ClientName: p.ClientName,
        Premium: p.Premium,
        CPCs: p.CPCs,
      };
    });

    this.totalPendingPremium = this.filteredPolicies
      .filter(p => p.StatementStatus === 'Pending')
      .map(p => p.Premium ?? 0)
      .reduce(this.sum, 0);
    this.totalPendingCPCs = this.filteredPolicies
      .filter(p => p.StatementStatus === 'Pending')
      .map(p => p.CPCs ?? 0)
      .reduce(this.sum, 0);
    this.totalPostedPremium = this.filteredPolicies
      .filter(p => p.StatementStatus === 'Posted')
      .map(p => p.Premium ?? 0)
      .reduce(this.sum, 0);
    this.totalPostedCPCs = this.filteredPolicies
      .filter(p => p.StatementStatus === 'Posted')
      .map(p => p.CPCs ?? 0)
      .reduce(this.sum, 0);
    this.isLoading = false;
  }

  private sum(accumulator: number, currentValue: number): number {
    return accumulator + currentValue;
  }

  private handleGetPoliciesSuccess(res: CpcPolicy[]): void {
    this.allPolicies = res;
    this.setTable();
    this.amplitudeEventService.logBusinessReporting(this.reportType || 'undefined', this.policyListType, this.allPolicies.length);
    this.isError = false;
    this.isLoading = false;
    if (this.currentFilters.CommisionGroupsFilterOn) {
      this.needToQueryAgentPolicies = true;
    } else {
      this.needToQueryAgentPolicies = false;
    }
  }

  private handelGetPoliciesFailure(): void {
    this.isError = true;
    this.snackbar.openSnackbar('Sorry, an error has occurred. Please try again later.', ToastClassEnum.warning);
  }

}
