import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Table, TableHeader } from 'src/app/shared/classes/table';
import { InvestmentTableHeaders } from '../../constants/investmentPolicyTableHeaders.constants';
import { RiaAccount } from '../../models/riaAccount.model';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { fadeIn } from 'src/app/animations';

@Component({
  animations: [fadeIn],
  selector: 'app-investment-policy-table',
  templateUrl: './investment-policy-table.component.html',
  standalone: false
})
export class InvestmentPolicyTableComponent implements OnChanges {
  @ViewChild(CdkVirtualScrollViewport, {static: false}) public virtualScroll?: CdkVirtualScrollViewport;
  @Input() riaAccounts: RiaAccount[] = [];
  @Input() reportType!: string;
  @Output() tableSortedEvent: EventEmitter<string> = new EventEmitter<string>();
  table: Table<RiaAccount> = new Table<RiaAccount>(InvestmentTableHeaders, 1, true);
  isLoading = true;
  isError = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes[ 'riaAccounts' ]?.isFirstChange() && changes[ 'riaAccounts' ]?.previousValue !== changes[ 'riaAccounts' ]?.currentValue) {
      this.table.setBody(this.riaAccounts);
      this.isLoading = false;
    }
  }

  orderTableBy(col: TableHeader<RiaAccount>): void {
    this.table.orderBy(col);
    this.tableSortedEvent.emit('table sorted');
  }
}
