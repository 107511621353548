import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QuestionnairePopupComponent } from 'src/app/portal/modals/questionnaire-popup/questionnaire-popup.component';
import { nationwideLifeHtml } from 'src/app/shared/constants/nationwide-life.constant';
import { TermLifeSsoModaltermLifeSsoModalComponent } from 'src/app/portal/modals/term-life-sso-modalterm-life-sso-modal/term-life-sso-modalterm-life-sso-modal.component';
import { GetContractedComponent } from 'src/app/portal/components/carriers-training/components/get-contracted/get-contracted.component';
import { ContractingLicensesComponent } from 'src/app/portal/modals/contracting-licenses/contracting-licenses.component';

@Injectable({
  providedIn: 'root'
})

export class ContentMapService {
  constructor(private dialog: MatDialog) { }


  public readonly resourceToFunctionMap = new Map<string, () => void>([
    [
      resourceIdEnum.carePlanningPreQualificationForm,
      (): void => {
        this.dialog.open(QuestionnairePopupComponent);
      }
    ],
    [
      resourceIdEnum.nationwideCareMatterQuote,
      (): void => {
        const w = window.open();
        w?.document.write(nationwideLifeHtml);
      }
    ],
    [
      resourceIdEnum.termLifeQuoteEngine,
      (): void => {
        //open term life modal
        this.dialog.open(TermLifeSsoModaltermLifeSsoModalComponent);
      }
    ],
    [
      resourceIdEnum.contractingRequest,
      (): void => {
        this.dialog.open(GetContractedComponent);
      }
    ],
    [
      resourceIdEnum.stateLicenses,
      (): void => {
        this.dialog.open(ContractingLicensesComponent, {
          width: '125rem'
        });
      }
    ]
  ]);
}

// Keep as Enum for readability - 12/2023
// eslint-disable-next-line no-restricted-syntax
enum resourceIdEnum {
  carePlanningPreQualificationForm = 'fe83f194-d0e6-40ed-9dcf-79f847711391',
  nationwideCareMatterQuote = '408a04a9-2fb5-47aa-8f22-b9e37dda2740',
  termLifeQuoteEngine = '14f1344d-a3d1-4414-9be6-b34fc1e55344',
  contractingRequest = 'eebfedfc-a7d3-4746-af78-75cb2e9e5dfa',
  stateLicenses = '25eb64c4-a6b4-4de0-9f44-4687c30f94a7'
}