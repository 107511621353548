import { Component, Inject, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationData } from 'src/app/shared/models/modal-data.models';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  standalone: false
})
export class ConfirmationComponent implements AfterViewInit {

  constructor(public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationData) {
  }
  ngAfterViewInit(): void {
    const width = '48rem';
    this.dialogRef.updateSize(width);
  }
}

/**
 * Example use in another component. Make sure you specify the type of data being passed in (line 37) to help type checking:
 *
 * import { MatDialog } from '@angular/material/dialog';
 * import { ConfirmationComponent } from '../../modals/confirmation/confirmation.component'
 * ...
 * constructor(private dialog: MatDialog, ...)
 * ...
 * // this.dialog.open<SpecifyComponentType: ConfirmationComponent, SpecifyDataType: ConfirmationData, SpecifyAfterClosedResultType: boolean>
 * openConfirmationModal(): void {
 *   const ref = this.dialog.open<ConfirmationComponent, ConfirmationData, boolean>(ConfirmationComponent, {
 *     data : {
 *       title: "Confirmation title", //optional
 *       message: "Are sure you want to do the thing?",
 *       confirmationButtonText: "Do the thing", //optional
 *       isWarning: true, //optional
 *       hideCancelButton: true, //optional
 *     }
 *   });
 *
 *   ref.afterClosed().subscribe(result => {
 *     if (result) {
 *       // The user has confirmed, do the the thing
 *     } else {
 *       // The user did not confirm, do something else, or nothing at all
 *     }
 *   });
 * }
 */
