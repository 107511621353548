import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequirementModelInputData } from '../../models/policy-v2.models';

@Component({
  selector: 'app-policy-requirements-modal-v2',
  templateUrl: './policy-requirements-modal-v2.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `
  ],
  standalone: false
})
export class PolicyRequirementsModalV2Component implements AfterViewInit {

  constructor(
    private dialogRef: MatDialogRef<PolicyRequirementsModalV2Component, RequirementModelInputData>,
    @Inject(MAT_DIALOG_DATA) public data?: RequirementModelInputData,
  ) { }

  ngAfterViewInit(): void {
    this.updateDialogProp();
  }

  updateDialogProp(): void {
    this.dialogRef.updateSize('64rem', '76rem');
  }
}
