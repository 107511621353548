import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { PortalCookies } from 'src/app/shared/constants/browser-cookie.constants';

@Component({
  selector: 'app-annual-statement-announcement',
  imports: [
    MatButtonModule,
    RouterModule
  ],
  templateUrl: './annual-statement-announcement.component.html',
  styles: ':host {display: block}',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AnnualStatementAnnouncementComponent {
  hasSeenModal = false;

  constructor(
    private dialogRef: MatDialogRef<AnnualStatementAnnouncementComponent>,
    private router: Router,
    private amplitude: AmplitudeEventService,
  ) {
    this.checkSeenModalStatus();
    this.dialogRef.afterClosed().subscribe({
      next: () => {
        localStorage.setItem(PortalCookies.annualStatementAnnouncementSeen, this.hasSeenModal.toString());
      }
    });
  }

  checkSeenModalStatus(): void {
    this.hasSeenModal = !!localStorage.getItem(PortalCookies.crmIntegrations);
  }

  goTo(): void {
    this.router.navigate(['/Portal/Business/Fixed/Lifecycle']).then(() => {
      this.amplitude.logCustomEvent(undefined, 'Navigate to Lifecycle from Statement Announcement', false, false);
    });
    this.dialogRef.close();
  }
}
