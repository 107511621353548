import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { SearchApiService, SearchResultModelV2 } from '../../services/search-api.service';
import { Subject } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { ContentMapService } from 'src/app/core/services/content-map.service';
import { countMapString } from 'src/app/shared/helpers/count-map.helper';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  animations: [fadeIn],
  selector: 'app-global-search-v2',
  templateUrl: './global-search-v2.component.html',
  standalone: false
})

export class GlobalSearchV2Component implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput?: ElementRef;
  userInput?:string = ''; // the actual input from the user
  searchText?:string = ''; // usually the user input, but transforms into resource title after selection
  isSearching = true;
  searchSubject = new Subject<string>();
  searchResultsList: SearchResultModelV2[] = [];
  itemSelected = false;

  constructor(
    private searchApi: SearchApiService,
    private amplitudeEventService: AmplitudeEventService,
    public contentMap: ContentMapService
  ) { }

  ngOnInit(): void {
    this.searchSubject
      .pipe(
        tap(text => {
          this.userInput = text;
          this.itemSelected = false;
        }),
        filter(text => {
          if (text) return true; // make sure search term isn't an empty string
          return false;
        }),
        tap(() => {
          this.isSearching = true;
        }),
        debounceTime(250),
        switchMap(text => {
          return this.searchApi.search(text);
        }))
      .subscribe({
        next: (results) => {
          this.searchResultsList = results;
          this.isSearching = false;
        },
        error: () =>  {
          this.isSearching = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.searchSubject.unsubscribe();
  }

  displayFn(result?: SearchResultModelV2): string {
    const name = result?.Title || '';
    return name;
  }

  resultSelected($event: MatAutocompleteSelectedEvent): void {
    this.itemSelected = true;
    this.logSearchTracking($event.option.value);
    this.searchResultsList = [];
    this.searchText = undefined;
    this.searchInput?.nativeElement?.blur();
  }

  /**
   * For analytics tracking when someone leaves the search without clicking on an option.
   * Aiming to capture case when user does not get relevant results they can use
   */
  trackClickAway(): void {
    // Exit method for case when blur is triggered by user selecting an option
    if (this.itemSelected) {
      return;
    }

    this.logSearchTracking(null);
    this.searchResultsList = [];
    this.searchText = undefined;
  }

  getSearchTrackingScenario(entry: string, results: string, selected: string): string {
    return entry && results !== searchEnum.noResultSelected && selected !== searchEnum.noResultSelected
      ? searchScenario.foundAndClick //Search Term, Search Results, Search Result Selected
      : selected === searchEnum.noResultSelected && results === searchEnum.noResultFound
        ? searchScenario.noResults //Search Term, NULL (Empty Array), NULL
        : searchScenario.foundButNoClick; //Search Term, Search Results, NULL
  }

  logSearchTracking(selectedResult: SearchResultModelV2 | null): void {
    const eventType = 'Global Search V2';
    const clickTarget = this.userInput || searchEnum.noSearchText;
    const selected = selectedResult ? selectedResult?.Title : searchEnum.noResultSelected;
    const results = this.searchResultsList.length ? this.searchResultsList?.map(p => p.Title).join(', ') : searchEnum.noResultFound;
    const eventProps = {
      scenario: this.getSearchTrackingScenario(this.userInput || '', results, selected),
      search_text: this.userInput,
      search_results: results,
      search_results_count: countMapString(this.searchResultsList.length),
      selected_result: selected
    };

    this.amplitudeEventService.logCustomEvent('Search Term: ' + clickTarget, eventType, eventProps, false);
  }
}

const enum searchEnum {
  noSearchText = 'No search text',
  noResultSelected = 'No result selected',
  noResultFound = 'No results found',
}


const enum searchScenario {
  foundAndClick = 'Search results found and result was clicked',
  noResults = 'No search results found',
  foundButNoClick = 'Search results found but no click'
}