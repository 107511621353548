import { Component, Input, OnInit } from '@angular/core';
import { policyStages } from 'src/app/shared/constants/policyList.constants';
import { FundingFrequencyIdEnum } from 'src/app/shared/enums/policy-details.enum';
import { PolicyDetail, PolicyFunding, PolicyStage } from 'src/app/shared/models/policy.models';

@Component({
  selector: 'app-policy-details-summary',
  templateUrl: './policy-details-summary.component.html',
  standalone: false
})
export class PolicyDetailsSummaryComponent implements OnInit {
  @Input() policyDetails?: PolicyDetail;
  @Input() policyFundings?: PolicyFunding[];
  frequencyId = FundingFrequencyIdEnum;
  constructor() { }

  ngOnInit(): void {
    this.createSummaryView();
  }
  //TODO: Ideally this component should internalize the desired timeline stage history items to display then pass that array into a child component.
  // That may help keep all the different conditions based on LOB in checks
  createSummaryView(): void {
    if (!this.policyDetails) return;
    this.policyDetails.StageHistoryObject = this.getStageHistory();
  }

  getStageHistory(): (PolicyStage | null)[] {
    const stages: (PolicyStage | null)[] = [];
    policyStages.forEach(stage => {
      stages.push(this.filterStageById(stage));
    });

    return stages;
  }

  filterStageById(stageId: number): PolicyStage | null {
    if (!this.policyDetails) return null;

    const stage = this.policyDetails.StageHistory.filter(row => row.PolicyStageId === stageId);
    if (stage.length > 0) return stage[0];
    return null;
  }

}
